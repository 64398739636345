import React, { FC, useState, useEffect } from 'react';
import API from 'repository';
import trackingConst from 'config/TrackingConst';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
    formatPriceServiceToNum,
    formatPriceWithKCharacter,
    removeDuplicateGroupService,
} from 'utils/BookingUltils';
import { ISelected } from '../../types/Selected';
import KEY from '../../Const';
import Selected from '../components/Selected';
import Title from '../components/Title';
import { Modal, notification } from 'antd';
import Services from 'pages/bookingNew/ServiceScreen/ListServices';
import ServiceScreen from 'pages/bookingNew/ServiceScreen';
import { IListService, IService, IServiceGroup } from 'pages/bookingNew/types/Service';
import ListCampaignScreen, { fetchCampaign } from 'pages/bookingNew/CampaignScreen/ListCampaignScreen';
import { useSelector } from 'react-redux';
import { setChosenServiceWithCampaign } from 'pages/bookingNew/CampaignScreen';
import { useMediaQuery } from 'react-responsive';
import iconArrowLeft from 'images/icons/icon_arrow_left_blue.svg';
import CampaignDetailScreen from 'pages/bookingNew/CampaignDetailScreen';


const initListService = () => {
    const list = [];
    for (let i = 0; i < 4; i += 1) {
        const item = {
            serviceGroups: [],
            categoryName: '',
            order: i,
            title: '',
        };
        list.push(item);
    }
    return list;
};

const ServiceGroup: FC<{
    onChangeSelected: Function;
    selected: ISelected;
    salonId: number;
    index: number;
    // setHasClickNextInSalon: Function;
    trackingData: any;
    // hasClickInService: number;
    // guideStep5: string;
    isLoadingServices: boolean;
    allowChange: boolean;
    isBookingGroup: boolean;
    ruleShowBooking: any;
    listCampaignTags: CampaignServiceTagType[];
    listSelected: ISelected[];
    
    // setListDataChosenGroup: Function;
}> = ({
    onChangeSelected,
    selected,
    // setHasClickNextInSalon,
    trackingData,
    // hasClickInService,
    // guideStep5,
    isLoadingServices,
    allowChange,
    salonId,
    isBookingGroup,
    // setListDataChosenGroup,
    index,
    ruleShowBooking,
    listCampaignTags,
    listSelected
}) => {
        const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
        const [tabs, setTabs] = useState('1');
        
    const listChosenService = selected[KEY.CHOSEN_SERVICES] || [];
    const [message, setMessage] = useState('');
    const [totalMoney, setTotalMoney] = useState(0);
    const [isCampaignScreen, setIsCampaignScreen] = useState<boolean>(false);
    const [isDetailCampaign, setIsDetailCampaign] = useState<boolean>(false);
    const [layoutDefault, setLayoutDefault] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isGenZMember, setIsGenZMember] = useState<boolean>(false);
    const [showDetailServiceGroup, setShowDetailServiceGroup] = useState<
        IServiceGroup
    >({
        groupId: 1,
        groupName: '',
        services: [],
        upsaleServices: [],
    });
    const [listService, setListService] = useState<IListService[]>(
        initListService,
    );
    
    const [positionScroll, setPositionScroll] = useState<number>(0);
    const common = useSelector((state: any) => state.common);
    const [serviceSelected, setServiceSelected] = useState<IService[]>([])
    const [campaignSelected, setCampaignSelected] = useState<CampaignType[]>([])
    const [
                campaignDetailSelected,
                setCampaignDetailSelected,
            ] = useState<CampaignType | null>(null);
    const { step } = selected;
    const postTracking = async (data: any) => {
        try {
            await API.postTrackingDynamic(data);
        } catch (e) {
            console.log(e.message);
        }
    };

    const handleOnChangeService = () => {
        if (salonId === 0) {
            // setHasClickNextInSalon(0);
            const data = {
                data: {
                    customerId: trackingData.customerId,
                    phone: selected[KEY.PHONE],
                    salonId,
                    chosenServices: JSON.stringify(listChosenService),
                    dateBook: selected[KEY.DATE_BOOK],
                    stylistId: selected[KEY.STYLIST].id,
                },
                pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
                screenId:
                    trackingConst.Screens.BOOKING_SELECT_BOOKING_SINGLE_SCREEN,
                eventId: trackingConst.Events.CLICK_BUTTON_SERVICE,
                tokenKey: trackingData.token,
                source: trackingData.source,
                utmSource: trackingData.utmSource,
            };
            postTracking(data);
        } else {
            onChangeSelected(KEY.STEP, 2, index);
            // setHasClickNextInSalon(1);
        }
    };

    const getClassServiceStep = () => {
        const isSalonSelected = selected[KEY.SALON].id > 0;
        if (!isSalonSelected) return '';
        if (
            isSalonSelected &&
            (selected[KEY.CHOSEN_SERVICES].length > 0 || allowChange)
        )
            return 'main-screen__block--done';
        if (isSalonSelected && selected[KEY.CHOSEN_SERVICES].length === 0)
            return 'main-screen__block--active';
        return '';
    };

    // useEffect(() => {
    //     if (!allowChange) {
    //         if (selected[KEY.CHOSEN_SERVICES].length === 0) {
    //             if (hasClickInService === 0)
    //                 setMessage('Mời bạn chọn dịch vụ để chọn giờ cắt');
    //             if (hasClickInService === -1)
    //                 setMessage(
    //                     'Một số dịch vụ đã ngừng bán, bạn vui lòng chọn lại dịch vụ',
    //                 );
    //         } else {
    //             setMessage('');
    //         }
    //     } else {
    //         setMessage('');
    //     }
    // }, [allowChange, hasClickInService, selected[KEY.CHOSEN_SERVICES]]);

    useEffect(() => {
        if (listChosenService) {
            setTotalMoney(
                listChosenService.reduce(
                    (acc, curr) =>
                        acc + formatPriceServiceToNum(curr.price || ''),
                    0,
                ),
            );
        }
    }, [listChosenService]);

    const [isShowPopup, setIsShowPopup] = useState(false);

    // function setCampaignDetailSelected(detailCampaign: CampaignType) {
    //     throw new Error('Function not implemented.');
    // }

  

    useEffect(() => {
      if(isShowPopup) {
        setServiceSelected(selected[KEY.CHOSEN_SERVICES]);
        setCampaignSelected(selected[KEY.CHOSEN_CAMPAIGN])
      }
    }, [isShowPopup])
    

    // const prevStep = usePrevious(step);

    const getListServiceScreen = (
        list: any,
        chosenCampaign = selected.chosenCampaign,
    ) => {
        return list.map((category: any) => {
            return {
                ...category,
                serviceGroups: category.serviceGroups.map((svGroup: any) => {
                    const newListService = svGroup.services.map(
                        (service: any) => {
                            let campaignIdApply = null;
                            let newService = { ...service };
                            selected[KEY.LIST_SERVICES].rulesSpecialDay.map(
                                (rules: any) => {
                                    const isRuleDate = rules?.date?.includes(
                                        selected[KEY.DATE_BOOK],
                                    );
                                    if (isRuleDate) {
                                        if (
                                            rules?.serviceSpecial &&
                                            service.serviceId ===
                                            rules?.serviceId
                                        ) {
                                            newService = {
                                                ...rules.serviceSpecial,
                                                isDefaultService:
                                                    service.isDefaultService,
                                            };
                                        } else if (
                                            rules?.serviceSpecialStylist &&
                                            rules?.serviceStylist?.serviceId ===
                                            service.serviceId
                                        ) {
                                            newService = {
                                                ...rules.serviceSpecialStylist,
                                                isDefaultService:
                                                    service.isDefaultService,
                                            };
                                        }
                                    }
                                },
                            );
                            let newPrice = newService.price;
                            let newOldPrice = newService.oldPrice;
                            if (chosenCampaign.length) {
                                chosenCampaign.map((campaign) => {
                                    if (
                                        campaign.serviceApplies &&
                                        campaign.serviceApplies.length &&
                                        campaign.valid
                                    ) {
                                        campaign.serviceApplies.map(
                                            (serviceCampaign) => {
                                                if (
                                                    newService.serviceId ===
                                                    serviceCampaign.id
                                                ) {
                                                    const priceService = formatPriceServiceToNum(
                                                        newService.price || '0',
                                                    );
                                                    if (
                                                        priceService >
                                                        serviceCampaign.price
                                                    ) {
                                                        newPrice = formatPriceWithKCharacter(
                                                            serviceCampaign.price,
                                                        );
                                                        newOldPrice =
                                                            serviceCampaign.originPrice >
                                                                0
                                                                ? formatPriceWithKCharacter(
                                                                    serviceCampaign.originPrice,
                                                                )
                                                                : service?.price ||
                                                                '0';
                                                        campaignIdApply =
                                                            campaign.campaignId;
                                                    }
                                                }
                                            },
                                        );
                                    }
                                });
                            }

                            const campaignTagsList = listCampaignTags.find(
                                (campaign) =>
                                    campaign.serviceId === newService.serviceId,
                            );

                            return {
                                ...newService,
                                price: newPrice,
                                oldPrice: newOldPrice,
                                campaignId: campaignIdApply,
                                serviceImage: newService?.serviceImage
                                    ? newService.serviceImage
                                    : service?.serviceImage
                                        ? service.serviceImage
                                        : [],
                                campaignTags: campaignTagsList?.campaign || [],
                            };
                        },
                    );
                    return {
                        ...svGroup,
                        services: newListService,
                    };
                }),
            };
        });
    };

    const fetchCampaignOnChangeService = async (selectedService: any) => {
        try {
            const data: ListCampaignType = await fetchCampaign(
                selectedService,
                selected.chosenCampaign,
                selected.customerPhone,
                selected.salon.id,
            );
            if (data) {
                const newData = [...data.salon, ...data.customer];
                const chosenCampaignIds = selected.chosenCampaign.map(
                    (item) => item.campaignId,
                );
                const newChosenCampaign = newData.filter((item) =>
                    chosenCampaignIds.includes(item.campaignId),
                );
                const newSelectedService = setChosenServiceWithCampaign(
                    selectedService,
                    newChosenCampaign,
                    selected.listAllService,
                );
                // setChosenCampaign(newChosenCampaign);
                // onChangeChosenService(newSelectedService, selected[KEY.INDEX]);
                if (selected[KEY.LIST_SERVICES].data) {
                    const newListServiceScreen = getListServiceScreen(
                        selected[KEY.LIST_SERVICES].data,
                        newChosenCampaign,
                    );
                    setListService(newListServiceScreen);
                }
            }
            // setListCampaign(data);
        } catch (error) { }
    };

    const onSelectServiceDetailScreen = (newSelectedService: any) => {
        // onChangeChosenService(newSelectedService, selected[KEY.INDEX]);
        fetchCampaignOnChangeService(newSelectedService);
        const dataTracking: any = {
            pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
            screenId:
                trackingConst.Screens.BOOKING_SELECT_DETAIL_SERVICE_SCREEN,
            tokenKey: trackingData.token,
            source: trackingData.source,
            utmSource: trackingData.utmSource,
            eventId: trackingConst.Events.CLICK_SELECT_SERVICE,
            data: {
                phone: selected[KEY.PHONE],
                salonId: selected[KEY.SALON].id.toString(),
                chosenServices: JSON.stringify(newSelectedService),
                customerId: trackingData?.userId || 0,
            },
        };
        postTracking(dataTracking);
    };

    const onSelectService = async (
        service: any,
        checked: boolean,
        isScreenIdServices = false,
    ) => {
        // debugger
        const {
            listService: listSubService,
            ...serviceWithoutSubService
        } = service;
        let newSelectedService = [];

        if (checked) {
            let listServiceWithOutDuplicate = removeDuplicateGroupService(
                serviceSelected,
                service,
            );
            // add parent service
            if (service.parentServiceId) {
                const hasExistParent =
                    listServiceWithOutDuplicate.findIndex(
                        (serv: any) =>
                            serv.serviceId === service.parentServiceId,
                    ) !== -1;
                if (!hasExistParent) {
                    listService.forEach((category: any) => {
                        category.services.forEach((item: any) => {
                            if (item.serviceId === service.parentServiceId) {
                                const {
                                    listService,
                                    ...serviceWithoutSub
                                } = item;
                                listServiceWithOutDuplicate = removeDuplicateGroupService(
                                    listServiceWithOutDuplicate,
                                    serviceWithoutSub,
                                );
                                listServiceWithOutDuplicate.push(
                                    serviceWithoutSub,
                                );
                            }
                        });
                    });
                }
            }
            const listServiceWithOutDuplicateGroup = [
                ...listServiceWithOutDuplicate,
            ].filter((sv: any) => service.groupId !== sv.groupId);

            newSelectedService = [
                ...listServiceWithOutDuplicateGroup,
                serviceWithoutSubService,
            ];
        } else {
            newSelectedService = serviceSelected.filter(
                (selectedService) =>
                    selectedService.serviceId !== service.serviceId,
            );
            if (listSubService && listSubService.length > 0) {
                newSelectedService = newSelectedService.filter(
                    (selectedService) =>
                        selectedService.parentServiceId !== service.serviceId,
                );
            }
        }
        setServiceSelected(newSelectedService);
        // onChangeSelected([KEY.CHOSEN_SERVICES],newSelectedService, selected[KEY.INDEX]);
        fetchCampaignOnChangeService(newSelectedService);
        // tracking
        const dataTracking: any = {
            pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
            screenId: isScreenIdServices
                ? trackingConst.Screens.BOOKING_SELECT_SERVICE_SCREEN
                : trackingConst.Screens.BOOKING_SELECT_DETAIL_SERVICE_SCREEN,
            tokenKey: trackingData.token,
            source: trackingData.source,
            utmSource: trackingData.utmSource,
            eventId: checked
                ? trackingConst.Events.CLICK_SELECT_SERVICE
                : trackingConst.Events.CLICK_REMOVE_SERVICE,
            data: {
                phone: selected[KEY.PHONE],
                salonId: selected[KEY.SALON].id.toString(),
                chosenServices: JSON.stringify(newSelectedService),
                customerId: trackingData?.userId || 0,
            },
            extendData: {
                serviceId: service.serviceId.toString(),
            },
        };
        if (
            Object.prototype.hasOwnProperty.call(service, 'campaignId') &&
            service.campaignId
        ) {
            dataTracking.data.campaignId = service.campaignId;
        }

        postTracking(dataTracking);
    };

    const getData = (list: any) => {
        if (list.data) {
            setIsLoading(false);
            const listCategoryOder = list.data.sort(
                (catA: any, catB: any) => catA.order - catB.order,
            );
            setListService(listCategoryOder);

            const listNewServices = getListServiceScreen(list.data);
            setListService(listNewServices);

            if (selected[KEY.CHOSEN_SERVICES].length === 0) {
                const defaultSelectedServices: any = [];

                listNewServices.forEach((category: any) => {
                    category.serviceGroups.forEach((svGroup: any) => {
                        svGroup.services.forEach((service: any) => {
                            if (service.selected === 1) {
                                const {
                                    listService,
                                    ...serviceWithoutSubService
                                } = service;
                                defaultSelectedServices.push(
                                    serviceWithoutSubService,
                                );
                            }
                            if (
                                service.listService &&
                                service.listService.length > 0
                            ) {
                                service.listService.forEach((service: any) => {
                                    if (service.selected === 1)
                                        defaultSelectedServices.push(service);
                                });
                            }
                        });
                    });
                });
                setServiceSelected(defaultSelectedServices);
                // onChangeSelected([KEY.CHOSEN_SERVICES],defaultSelectedServices, selected[KEY.INDEX]);
                // onChangeChosenService(defaultSelectedServices);
            } else {
                const defaultSelectedServices: any = [];

                const chosenServiceIds = selected[KEY.CHOSEN_SERVICES].map(
                    (item) => item.serviceId,
                );

                listNewServices.forEach((category: any) => {
                    category.serviceGroups.forEach((svGroup: any) => {
                        svGroup.services.forEach((service: any) => {
                            if (chosenServiceIds.includes(service.serviceId)) {
                                defaultSelectedServices.push(service);
                            }
                        });
                    });
                });
                setServiceSelected(defaultSelectedServices);
                // onChangeSelected([KEY.CHOSEN_SERVICES],defaultSelectedServices, selected[KEY.INDEX]);

                // onChangeChosenService(defaultSelectedServices);
                fetchCampaignOnChangeService(defaultSelectedServices);
            }
        }
        setIsGenZMember(list.isAlertCampaign || false);
    };

    useEffect(() => {
        if (selected[KEY.LIST_SERVICES]) {
            getData(selected[KEY.LIST_SERVICES]);
        }
    }, [selected[KEY.LIST_SERVICES], listCampaignTags]);

    useEffect(() => {
        window.scrollTo(0, 0);
        // setPreviOusChosenServices();
    }, []);

    // useEffect(() => {
    //     if (prevStep === 3 && step === 2) {
    //         window.scrollTo({ top: positionScroll });
    //     }
    // }, [step]);

    const onHandleSelectService = () => {
        onChangeSelected(KEY.CHOSEN_SERVICES, serviceSelected, selected[KEY.INDEX]);
        setIsShowPopup(false);
    }

     const changeChosenServiceBooking = (
            listChosenCampaign: CampaignType[],
            chosenSv = serviceSelected,
        ) => {
            const newListChoSenService = setChosenServiceWithCampaign(
                chosenSv,
                listChosenCampaign,
                selected.listAllService,
            );
            setServiceSelected(newListChoSenService);
            // onChangeSelected(KEY.CHOSEN_SERVICES, newListChoSenService, selected.index);
        };
    
        const onClickSelectCampaign = (campaign: CampaignType) => {
            let newChosenCampaign = [...campaignSelected];
    
            const existChosenCampaign = campaignSelected.find(
                (item) => item.campaignId === campaign.campaignId,
            );
            if (existChosenCampaign) {
                newChosenCampaign = campaignSelected.filter(
                    (item) => item.campaignId !== campaign.campaignId,
                );
                onChangeSelected(KEY.CHOSEN_CAMPAIGN, newChosenCampaign, selected.index)
            } else {
                newChosenCampaign.push(campaign);
            }
            setCampaignSelected(newChosenCampaign)
            // onChangeSelected(KEY.CHOSEN_CAMPAIGN, newChosenCampaign, selected.index)
            changeChosenServiceBooking(newChosenCampaign);
        };

        const handleGotoCampaignDetail = (campaign: CampaignType) => {
            setCampaignDetailSelected(campaign);
            // gotoCampaignDetail();
        };

        const onApplyCampaign = () => {
            onChangeSelected(KEY.CHOSEN_CAMPAIGN, campaignSelected, selected.index, KEY.CHOSEN_SERVICES, serviceSelected);
            setIsCampaignScreen(false)
        }

        const onOpenPopupService = () => {
              if(selected.index > 0) {
                const indexItem  = selected.index;
                const prevChosenService = listSelected[indexItem - 1][KEY.CHOSEN_SERVICES];
                const bookHours = listSelected[indexItem - 1][KEY.BOOK_HOURS];
                if(!prevChosenService?.length) {
                    const config = {
                        message: 'Thông Báo',
                        description:
                        `Chưa chọn dịch vụ của khách hàng ${indexItem}`,
                            duration: 5,
                            className: 'error',
                    };
                    notification.error(config);
                    return
                }
                if(!bookHours?.length) {
                    const config = {
                        message: 'Thông Báo',
                        description:
                        `Chưa chọn giờ của khách hàng ${indexItem}`,
                            duration: 5,
                            className: 'error',
                    };
                    notification.error(config);
                    return
                }
                setIsShowPopup(true)
            }
            setIsShowPopup(true)
        }
    

    return (
        <div
            id="serviceAttributeId"
        >
            <Selected
                type="service"
                value={
                    listChosenService.length
                        ? `Đã chọn ${listChosenService.length} dịch vụ`
                        : ''
                }
                placeholder="Xem tất cả dịch vụ hấp dẫn"
                message={message}
                onHandleClick={onOpenPopupService}
                required={
                    salonId !== 0 &&
                    listChosenService.length === 0
                }
            />
            {listChosenService.length > 0 ? (
                <>
                    <div className="block__box">
                        <div className="mt-4">
                            {/* {guideStep5 && (
                                <div className="mb-2.5 py-1.5 px-2.5 text-xs inline-flex item-center rounded-r-xl bg-green-eaf6ee">
                                    <div className="pr-1.5">{guideStep5}</div>
                                    <InfoCircleOutlined
                                        style={{
                                            fontSize: 16,
                                            color: '#EF4E24',
                                        }}
                                    />
                                </div>
                            )} */}
                            <div className="flex flex-wrap -mx-1.5">
                                {listChosenService.map((service, index) => (
                                    <div
                                        key={service.serviceId}
                                        className="mx-1.5 mb-2.5 rounded px-1.5 border border-gray-bababa text-sm cursor-default"
                                    >
                                        {service?.name}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="text-sm text-[#EF4E24]">
                        Tổng số tiền bạn cần thanh toán:{' '}
                        <span className="font-normal">
                            {formatPriceWithKCharacter(totalMoney)}
                        </span>
                    </div>
                </>
            ) : null}
            <Modal visible={isShowPopup} footer={null} onCancel={() => {
                setIsShowPopup(false);
                setIsCampaignScreen(false);
                setCampaignSelected([]);
                setServiceSelected([]);
                setIsDetailCampaign(false);
            }} >
                <div style={{maxHeight: isMobile ? '60vh' : '80vh', overflowY: 'auto'}}>
                {
                isCampaignScreen ? (
                    campaignDetailSelected ? (
                        <div>
                            <img className='pointer'
                                src={iconArrowLeft}
                                alt=""
                                onClick={() => {
                                    setCampaignDetailSelected(null)
                                }}
                                role="presentation"
                                />
                        <CampaignDetailScreen
                            campaignDetailSelected={campaignDetailSelected}
                            onClickChosenCampaign={(campaign: CampaignType) => {
                                const existChosenCampaign = campaignSelected.find(
                                    (item) => item.campaignId === campaign.campaignId,
                                );
                                if (!existChosenCampaign) onClickSelectCampaign(campaign);
                                else changeChosenServiceBooking(campaignSelected);
                            }}
                            changeChosenServiceBooking={(listService: IService[]) =>
                                changeChosenServiceBooking(campaignSelected, listService)
                            }
                            chosenService={serviceSelected}
                            listAllServices={selected.listAllService}
                            handleGoToCampaignScreen={() => setCampaignDetailSelected(null)}
                            salonId={salonId}
                            tabs={tabs}
                    />
                        </div>
                       
                   ) : (
                    <div>
                    <div className='flex justify-between py-3 px-2.5 pointer'>
                    <img
                                src={iconArrowLeft}
                                alt=""
                                onClick={() => {
                                setCampaignSelected(selected.chosenCampaign);
                                setIsCampaignScreen(false)
                                }}
                                role="presentation"
                            />
                        <span className="text-center text-[#20489B] font-semibold">Ưu đãi từ Moyo</span>
                        <div/>
                    </div>
                    <ListCampaignScreen
                        handleGotoCampaignDetail={(campaign: CampaignType) =>
                            handleGotoCampaignDetail(campaign)
                        }
                        phone={selected.customerPhone}
                        salonId={salonId}
                        chosenService={serviceSelected}
                        onClickChosenCampaign={(campaign: CampaignType) =>
                            onClickSelectCampaign(campaign)
                        }
                        chosenCampaign={campaignSelected}
                        listCampaign={selected.listCampaign}
                        setListCampaign={(list: ListCampaignType) =>  onChangeSelected(KEY.LIST_CAMPAIGN, list, selected.index)}
                        setChosenCampaign={(list: CampaignType[]) =>
                            onChangeSelected(
                                KEY.CHOSEN_CAMPAIGN,
                                list,
                                selected.index
                            )
                        }
                        tabs={tabs}
                        index={selected.index}
                        setTabs={(tab: string) => setTabs(tab)}
                    />
                    <div className="button-affix">
                    <div
                        className={`flex space-between text-center content-step time-line--active`}
                    >
                        <div
                            className={`right button-next pointer ${
                                campaignSelected?.length === 0 ? 'btn-inactive' : ''
                            }`}
                            onClick={onApplyCampaign}
                            role="presentation"
                        >
                            <span>ÁP DỤNG ƯU ĐÃI</span>
                        </div>
                    </div>
                </div>
                    </div>
                   )
                ) : (
                    <Services
                        isBookingGroup={true}
                        selected={selected}
                        listService={listService}
                        setShowDetailService={setShowDetailServiceGroup}
                        onSelectService={onSelectService}
                        isLoading={isLoading}
                        isGenZMember={isGenZMember}
                        trackingData={trackingData}
                        userId={trackingData.userId}
                        onChangeStep={() => {}}
                        setPositionScroll={setPositionScroll}
                        gotoCampaignScreen={() => {}}
                        ruleShowBooking={ruleShowBooking}
                        layoutDefault={layoutDefault}
                        setLayoutDefault={setLayoutDefault}
                        setAllowChange={() => {}}
                        allowChange={allowChange}
                        gotoCampaignDetail={(detailCamp: CampaignType) =>
                         {}
                        }
                        serviceSelected={serviceSelected}
                        onHandleSelectService={onHandleSelectService}
                        setIsCampaignScreen={setIsCampaignScreen}
                    />
                )
                }
                
                </div>
                   
            </Modal>
        </div>
    );
};

export default ServiceGroup;
