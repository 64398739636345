import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTitleModalLogin, showModalLogin } from 'actions/common';
import { setBookingPhone } from 'actions/booking';
import { ISelected } from 'pages/bookingNew/types/Selected';
import KEY from 'pages/bookingNew/Const';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { notification } from 'antd';

const SelectNumberGroupBooking: React.FC<{
    selected: ISelected;
    onChangeSelected: Function;
    setListSelected?: Function;
    listSelected?: ISelected[];
}> = ({ selected, onChangeSelected, listSelected, setListSelected }) => {
    const user = useSelector((state: any) => state.user);
    const dispatch = useDispatch();
    const role = useSelector((state: any) => state.role);

    const onClickLogin = () => {
        dispatch(showModalLogin(true));
        dispatch(setBookingPhone(selected[KEY.PHONE]));
        dispatch(setTitleModalLogin('Đăng nhập để đặt lịch theo nhóm'));
    };

    const OnClickChangeValue = (type: number) => {
        if (type === 2) {
            if (user?.userId && selected[KEY.GROUP_BOOKING] != 5) {
                if(!selected?.salon?.id) {
                    const config = {
                        message: 'Thông Báo',
                        description:
                            'Bạn vui lòng chọn salon để đặt lịch',
                        duration: 5,
                        className: 'error',
                    };
                    notification.error(config);
                    return;
                }
                if (
                    Number(user.phoneNumber) === Number(selected[KEY.PHONE]) ||
                    role.allowMultiple
                ) {
                    onChangeSelected(
                        KEY.GROUP_BOOKING,
                        selected[KEY.GROUP_BOOKING] + 1,
                    );
                } else {
                    onChangeSelected(KEY.GROUP_BOOKING, 1);
                    const config = {
                        message: 'Thông Báo',
                        description:
                            'Bạn không thể đặt theo nhóm khi dùng số khác để đặt lịch.',
                        duration: 5,
                        className: 'error',
                    };
                    notification.error(config);
                }
            } else if (!user?.userId) {
                onClickLogin();
            }
        } else if (type === 1 && selected[KEY.GROUP_BOOKING] != 1) {
            onChangeSelected(
                KEY.GROUP_BOOKING,
                selected[KEY.GROUP_BOOKING] - 1,
            );
        } else {
        }
    };

    useEffect(() => {
        if (user?.userId === 0) {
            onChangeSelected(KEY.GROUP_BOOKING, 1);
        }
    }, [user?.userId]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (selected[KEY.GROUP_BOOKING] === 1) {
            onChangeSelected(KEY.BOOK_HOURS, []);
            onChangeSelected(KEY.HOUR, {
                bookHourId: 0,
                subHourId: 0,
                secondSubHourId: 0,
                hour: '',
                hourFrame: '',
            });
        }
    }, [selected[KEY.GROUP_BOOKING]]);

    return (
        <div className="flex justify-between pb-2 font-semibold">
            <div className="flex">
                <img
                    className="w-5 h-5 mr-2"
                    src="/IconGroupBooking-0.svg"
                    alt=""
                />
                <span>Số người sử dụng dịch vụ:</span>
            </div>
            <div className="grid items-center justify-center grid-cols-3 gap-3 ml-5">
                <MinusCircleOutlined
                    className="text-xl"
                    onClick={() => OnClickChangeValue(1)}
                />
                <span className="flex items-center justify-center text-lg">
                    {selected[KEY.GROUP_BOOKING]}
                </span>
                <PlusCircleOutlined
                    className="text-xl"
                    onClick={() => OnClickChangeValue(2)}
                />
            </div>
        </div>
    );
};

export default SelectNumberGroupBooking;
