/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useState } from 'react';
import { Modal } from 'antd';
import { diffTimeToday } from 'utils/BookingUltils';
import { connect } from 'react-redux';
import api from 'repository';
import trackingConst from 'config/TrackingConst';
import { IService } from '../../../types/Service';

interface Props {
    count: number;
    trackingData: any;
    userId: any;
    phone: string;
    salonId: number;
    chosenServices: IService[];
    dateBook: string;
    stylistId: number;
    hourBook: any;
    hoursAvailable: string;
}

const StylistBusyModal: FC<Props> = ({
    count = 1,
    trackingData,
    userId,
    phone,
    salonId,
    chosenServices,
    dateBook,
    stylistId,
    hourBook,
    hoursAvailable,
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const [date, setDate] = useState({ diff: 0, name: 'Hôm nay' });

    async function postTracking(data: any) {
        try {
            api.postTrackingDynamic(data);
        } catch (e) {
            console.log(e.message);
        }
    }

    const trackingShowPopupStylistBusy = (isShow: boolean) => {
        const dataTracking = {
            pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
            screenId:
                trackingConst.Screens.BOOKING_SELECT_BOOKING_SINGLE_SCREEN,
            eventId: isShow
                ? trackingConst.Events.SHOW_POPUP_STYLIST_BUSY
                : trackingConst.Events.CLOSE_POPUP_STYLIST_BUSY,
            tokenKey: trackingData.token,
            source: trackingData.source,
            utmSource: trackingData.utmSource,
            data: {
                phone,
                salonId: salonId.toString(),
                chosenServices: JSON.stringify(chosenServices),
                dateBook,
                hoursAvailable: hoursAvailable.toString(),
                stylistId: stylistId.toString(),
                customerId: userId || 0,
                hourBook: hourBook.hour,
            },
        };
        postTracking(dataTracking);
    };

    const onCancel = () => {
        setIsVisible(false);
        trackingShowPopupStylistBusy(false);
    };

    useEffect(() => {
        let diff: any = 0;
        if (diffTimeToday(dateBook)) {
            diff = diffTimeToday(dateBook);
        }

        setDate({
            diff,
            name:
                diff === 0
                    ? 'hôm nay'
                    : diff === 1
                    ? 'ngày mai'
                    : diff === 2
                    ? 'ngày kia'
                    : `ngày ${dateBook}`,
        });
    }, [dateBook]);

    useEffect(() => {
        if (count < 0) {
            setIsVisible(true);
            trackingShowPopupStylistBusy(true);
        }
    }, [count]);

    return (
        <div className="">
            <Modal
                title="THÔNG BÁO"
                className="modal-stylist"
                visible={isVisible}
                footer={null}
                onCancel={onCancel}
            >
                <div className="text-center">
                    <div>
                        Stylist {date.name} bận,
                        <br />
                        Mời bạn chọn stylist khác!
                    </div>
                    <div
                        className="btn h3 bg-30s mg-top-20 padding-5"
                        onClick={() => onCancel()}
                    >
                        ĐỒNG Ý
                    </div>
                </div>
            </Modal>
        </div>
    );
};
function mapStateToProps(state: any) {
    const { trackingData, user } = state;
    return {
        trackingData,
        userId: user.userId,
    };
}

export default connect(mapStateToProps)(StylistBusyModal);
