import { Button, Modal, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import OtpInput from 'components/OtpInput/index.js';
import './styles.scss';
import { connect } from 'react-redux';
import API from 'repository';
import trackingConst from 'config/TrackingConst';
import { useHistory } from 'react-router';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import KEY from '../../Const';
import SentOtp from './SentOtp';

const ModalOtp: React.FC<{
    trackingData: any;
    selected: any;
    userId: any;
    visibleOtp: boolean;
    onCancel: Function;
    bookingData: any;
}> = ({
    trackingData,
    selected,
    userId,
    visibleOtp,
    onCancel,
    bookingData,
}) => {
    const [otpNumber, setOtpNumber] = useState('');
    const [message, setMessage] = useState('');
    const [verificationMethod, setVerificationMethod] = useState('zalo');
    const [countSendOtp, setCountSendOtp] = useState(0);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const onChangeOtp = (value: any) => {
        if (
            (value.charCodeAt(value.length - 1) > 47 &&
                value.charCodeAt(value.length - 1) < 58) ||
            !value
        ) {
            setOtpNumber(value);
            setMessage('');
        } else {
            setMessage('Mã xác minh là số và có 6 kí tự');
        }
    };

    const [otpCount, setOtpCount] = useState(0);

    const otpMethod = [
        'zalo',
        'sms', // 'voice'
    ];

    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleReCaptchaVerify = async () => {
        if (!executeRecaptcha) {
            console.error('Recaptcha not yet available');
            return '';
        }

        if (executeRecaptcha) {
            const token = await executeRecaptcha('');
            if (token) return token;
        }
        return '';
    };

    const onGetOtp = async () => {
        if (loading) return;
        try {
            setOtpNumber('');
            setMessage('');
            setLoading(true);

            const token = await handleReCaptchaVerify();
            if (token === '') {
                setOtpNumber('');
                setMessage('');
            } else {
                const data: any = await API.getOpt({
                    phone: selected.customerPhone,
                    method:
                        otpCount > otpMethod.length
                            ? otpMethod[otpCount % otpMethod.length]
                            : otpMethod[otpCount], // isSmsMethod ? 'sms' : 'voice',
                    recaptcha: token,
                });
                // const data: any = await API.getOpt({
                //     phone: selected.customerPhone,
                //     method: 'voice',
                // });
                if (data) {
                    if (data.success) {
                        setOtpNumber('');
                        setMessage('');
                    } else {
                        setOtpNumber('');
                        setMessage(data.message);
                    }
                    setCountSendOtp(data.allowResendAfterSeconds);
                    setVerificationMethod(data.verificationMethod);
                    console.log(
                        `setVerificationMethod: ${data.verificationMethod}`,
                    );
                }
                setOtpCount(otpCount + 1);
            }
        } catch (error) {
            console.log('Error sendOtp', error);
        }
        setLoading(false);
    };

    const onHandleSubmit = async () => {
        if (otpNumber.length !== 6) {
            setOtpNumber('');
            setMessage('Mã xác minh là số và có 6 kí tự');
            return;
        }
        if (loading) return;
        try {
            setLoading(true);
            const response: any = await API.checkOtp({
                bookingId: bookingData.id,
                phone: selected.customerPhone,
                code: otpNumber,
            });

            onCancel();
            const config = {
                message: 'Thông Báo',
                description: 'Đặt lịch thành công!',
                duration: 1,
                className: 'success',
            };
            notification.success(config);
            history.push({
                pathname: `/booking/success`,
                search: `?bookingId=${bookingData.id}`,
                state: {
                    bookingData: bookingData || undefined,
                },
            });
        } catch (error) {
            const config = {
                message: 'Thông báo',
                description: error.response.data.message
                    ? error.response.data.message
                    : 'Mã Xác Nhận Không Chính Xác!',
                duration: 3,
                className: 'error',
            };
            notification.error(config);
        }
        setLoading(false);
    };

    const postTracking = async (event: string) => {
        const dataTracking = {
            pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
            screenId: trackingConst.Screens.BOOKING_SUCCESS_SCREEN,
            eventId: event,
            tokenKey: trackingData.token,
            source: trackingData.source,
            utmSource: trackingData.utmSource,
            data: {
                phone: selected[KEY.PHONE],
                salonId: selected[KEY.SALON].id.toString(),
                dateBook: selected[KEY.DATE_BOOK],
                stylistId: selected[KEY.STYLIST].id.toString(),
                hoursAvailable: selected[KEY.AVAILABLE_HOURS].toString(),
                chosenServices: JSON.stringify(selected[KEY.CHOSEN_SERVICES]),
                hourBook: selected[KEY.HOUR].hour,
                customerId: userId || 0,
                skinnerId: selected[KEY.SKINNER_ID].toString(),
            },
        };
        try {
            API.postTrackingDynamic(dataTracking);
        } catch (e) {
            console.log(e.message);
        }
    };

    useEffect(() => {
        if (visibleOtp) onGetOtp();
    }, [visibleOtp]);

    const getMessage = (() => {
        switch (verificationMethod) {
            case 'sms':
                return 'Kiểm tra SMS để lấy OTP đăng nhập.';
            case 'voice':
                return 'Đợi cuộc gọi tự động để lấy OTP đăng nhập.';
            case 'zalo':
                return 'Kiểm tra Zalo để lấy OTP đăng nhập.';
            default:
                return '';
        }
    })();

    return (
        <div className="modal-otp-booking">
            <Modal
                visible={visibleOtp}
                title={`NHẬP MÃ OTP GỬI VỀ SĐT ${selected[KEY.PHONE]}`}
                className="modal-otp-booking"
                footer={false}
                width={480}
                closable={false}
            >
                <div className="pb-5 text-sm text-center md:text-base">
                    Vì lí do bảo mật, tránh nhầm SĐT.
                    <br />
                    Bạn vui lòng nhập mã OTP để xác nhận đặt lịch
                </div>
                <div className="otp ">
                    <OtpInput
                        value={otpNumber}
                        onChange={onChangeOtp}
                        numInputs={6}
                        isInputNum
                        focusStyle={{ borderColor: '#222222' }}
                        shouldAutoFocus
                        onKeyDown={(e: any) => {
                            if (e.keyCode === 13) {
                                onHandleSubmit();
                            }
                        }}
                        className="input-otp"
                    />
                </div>
                {message ? (
                    <div className="message__error">{message}</div>
                ) : null}
                {/* {verificationMethod ? (
                    <div className="message__otp">
                        {verificationMethod === 'sms'
                            ? 'Kiểm tra SMS '
                            : 'Nhận cuộc gọi tự động '}
                        để lấy OTP đặt lịch.
                    </div>
                ) : null} */}
                <div className="form__message">{getMessage}</div>
                <SentOtp
                    onGetOtp={onGetOtp}
                    count={countSendOtp}
                    setCount={(value: number) => setCountSendOtp(value)}
                    postTracking={postTracking}
                />
                <Button
                    type="primary"
                    onClick={() => onHandleSubmit()}
                    className="button btn-color-1"
                    loading={loading}
                >
                    HOÀN TẤT
                </Button>
            </Modal>
        </div>
    );
};

function mapStateToProps(state: any) {
    const { trackingData, user, booking } = state;
    return {
        trackingData,
        userId: user.userId,
    };
}
export default connect(mapStateToProps)(ModalOtp);
