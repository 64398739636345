import React, { FC, useEffect } from 'react';
import { Col, Row } from 'antd';
import { IStylist } from '../../../types/Stylist';
import { ExclamationCircleFilled } from '@ant-design/icons';
import star from '../../../../../images/icons/star.png';
import { Image } from 'antd';

interface Rating {
    Name: string;
    Star: number;
    TotalCustomer: number;
    TotalCustomerRating: number;
}
const DetailStylist: FC<{
    isLoading: boolean;
    isShowStylist: boolean;
    stylistSelected: IStylist;
}> = ({ isLoading, isShowStylist, stylistSelected }) => {
    const listImage = stylistSelected.favoriteImages
        ? JSON.parse(stylistSelected?.favoriteImages)
        : [];

    const listRaiting = stylistSelected?.ratingInfor
        ? JSON.parse(stylistSelected?.ratingInfor)
        : [];

    return !isLoading && isShowStylist && stylistSelected.staffId ? (
        <div className="bot-message bot-message__stylist fade-in">
            <div className="stylist-selected">
                <div className="stylist__top">
                    {stylistSelected.staffName && (
                        <div className="name">
                            Stylist: {stylistSelected.staffName}
                        </div>
                    )}
                    {/* đánh giá cũ */}
                    {/* <div className="skill">
                        {stylistSelected?.star > 0 && (
                            <div className="skill__content">
                                {stylistSelected?.star}⭐ kỹ năng
                            </div>
                        )}
                        {stylistSelected?.ratingStar > 0 && (
                            <div className="skill__content">
                                {stylistSelected?.ratingStar}⭐ giao tiếp
                            </div>
                        )}
                    </div> */}
                </div>
                {stylistSelected?.description && (
                    <div className="stylist__content"
                        dangerouslySetInnerHTML={{ __html: stylistSelected?.description }}
                    >
                    </div>
                )}

                {/* đánh giá mới */}
                {listRaiting?.length > 0 && (
                    <div className="stylist__listRaiting">
                        {listRaiting
                            .filter((item: Rating) => item?.TotalCustomer > 100)
                            .map((item: Rating, index: number) => (
                                <div className="rating" key={index} style={{ display: item?.TotalCustomerRating < 1 ? "none" : "" }}>
                                    <div className="rating__title">{item?.Name} {(item?.Star / item?.TotalCustomerRating).toFixed(1)}</div>
                                    <img className="rating__img" src={star} alt="Star" />
                                    <div className="rating__detail">
                                        {item?.TotalCustomer > 1000 ?
                                            <span>({(item?.TotalCustomer / 1000).toFixed(1)}k khách)</span>
                                            :
                                            <span>({item?.TotalCustomer} khách)</span>
                                        }
                                    </div>
                                    {index === 0 ?
                                        <div className="rating__icon">
                                            <ExclamationCircleFilled
                                                className="icon"
                                            />
                                            <span className="text">Dữ liệu phục vụ trong vòng 1 năm gần nhất</span>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            ))}
                    </div>
                )}


                {listImage.length > 0 && (
                    <div className="stylist__listImage">
                        <Row gutter={[8, 0]}>
                            {listImage.map((item: any, index: number) => (
                                <Col key={index} xs={6}>
                                    <div className='image-cover'>
                                        <Image
                                            className='image-stylist'
                                            src={item}
                                        />
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                )}
            </div>
        </div>
    ) : null;
};

export default DetailStylist;
