import React, { FC, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { setTimeSpecial } from 'actions/booking';
import { scrollToSmoothly } from 'utils/ScrollSmooth';
import KEY from '../Const';
import { IParams, ISelected, ISelectedSalonBackUp } from '../types/Selected';
import SelectedSalon from './Salon';
import Service from './Service';
import Stylist from './Stylist';
import DateBook from './Datebook';
import BoxTime from './ListTime';
import HoursSalonBackUp from './HoursSalonBackUp';
import './styles.scss';
import Title from './components/Title';
import ModalSuggestService from './SussgestService';
import Extension from './Extensions';
import ModalSelectConsultation from './SelectConsultation';
import { IService } from '../types/Service';
import SelectNumberGroupBooking from './SelectNumberGroupBooking';
import BoxTimeGroupBooking from './ListTimeGroupBooking';
import BookingGroup from './BookingGroup';

const TODAY = moment(new Date()).format('DD-MM-YYYY');
const dfHour = {
    bookHourId: 0,
    subHourId: 0,
    secondSubHourId: 0,
    hour: '',
};

interface Props {
    role: any;
    onChangeSelected: Function;
    moveSalonStylistbackUp: Function;
    selected: ISelected;
    trackingData: any;
    hasClickNextStep: number;
    changeCheckBox: Function;
    userInfo: any;
    hasClickInSalon: number;
    setHasClickNextInSalon: Function;
    onChangeSalon: Function;
    hasClickInService: number;
    setHasClickInService: Function;
    selectedSalonBackUp: ISelectedSalonBackUp;
    onChangeSelectedBackUp: Function;
    setIsSelectedSalonNear: Function;
    guideService: any;
    isLoadingServices: boolean;
    setListIconHotTime: Function;
    isBookStylistMaster: boolean;
    changeServiceWithChangeStylistDateBook: Function;
    isClickNextInService: boolean;
    previOusChosenServices: IService[];
    prevStep: number | undefined;
    allowChange: boolean;
    stylitIdFromHistory: number;
    result: any;
    setListSelected: Function;
    listSelected: ISelected[];
    ruleShowBooking: any;
    listCampaignTags: CampaignServiceTagType[]
}

const MainScreen: FC<Props> = ({
    changeCheckBox,
    role,
    onChangeSelected,
    moveSalonStylistbackUp,
    selected,
    trackingData,
    hasClickNextStep,
    hasClickInSalon,
    setHasClickNextInSalon,
    onChangeSalon,
    hasClickInService,
    setHasClickInService,
    selectedSalonBackUp,
    onChangeSelectedBackUp,
    setIsSelectedSalonNear,
    guideService,
    isLoadingServices,
    setListIconHotTime,
    isBookStylistMaster,
    changeServiceWithChangeStylistDateBook,
    isClickNextInService,
    previOusChosenServices,
    prevStep,
    allowChange,
    stylitIdFromHistory,
    result,
    setListSelected,
    listSelected,
    ruleShowBooking,
    listCampaignTags,
}) => {
    const [guideSelectSalon, setGuideSelectSalon] = useState('');
    const [guideSelectService, setGuideSelectService] = useState('');
    const [guideStep5, setGuideStep5] = useState('');
    const [isStylistBusy, setStylistBusy] = useState<number>(1);
    const [isSalonBusy, setSalonBusy] = useState<number>(1);
    const [isLoadingHour, setIsLoadingHour] = useState<boolean>(true);
    const [isClickDateBook, setIsClickDateBook] = useState<boolean>(false);
    const [isShowDateBook, setIsShowDateBook] = useState<boolean>(false);
    const [reception, setReception] = useState({
        avatar: '',
        name: '',
    });
    const [listSkinner, setListSkinner] = useState([]);
    const listChosenService = selected[KEY.CHOSEN_SERVICES];
    const [isMoyoHairService, setIsMoyoHairService] = useState(false);
    const [listDataChosenGroup, setListDataChosenGroup] = useState<ISelected[]>([]);

    const scrollToSelectDateBook = () => {
        const element = document.querySelector('#datebookId') as HTMLDivElement;
        if (element) {
            const elementPosition = element.getBoundingClientRect().top;
            const pos = elementPosition || 0;
            scrollToSmoothly(pos, 300);
        }
        if (!isShowDateBook) {
            setIsShowDateBook(true);
        }
    };

    function onChangeDay(item: any) {
        onChangeSelected(KEY.DATE_BOOK, item?.day ? item.day : TODAY, selected?.index);
        setIsClickDateBook(true);
    }
    
    function getClassHourStep(key: string) {
        switch (key) {
            case 'group':
                if(selected.salon.id) {
                    return selected[KEY.GROUP_BOOKING] > 0
                    ? 'main-screen__block--done'
                    : 'main-screen__block--active';
                } else {
                    return ''
                }
            case 'service':
                if(selected.salon.id && selected.groupBooking > 0)
                        return selected[KEY.CHOSEN_SERVICES].length
                            ? 'main-screen__block--done'
                            : 'main-screen__block--active';
                else return ''
            case 'hour': 
               if(selected?.chosenService?.length) {
                return selected[KEY.BOOK_HOURS].length
                            ? 'main-screen__block--done'
                            : 'main-screen__block--active';
               } else {
                 return ''
               }
            default:
                return '';
        }
    }
    


    useEffect(() => {
        if (role.guideScreens && role.guideScreens.length > 0) {
            const guideSalon = role.guideScreens.find(
                (item: any) => item.step === 1,
            );
            const guideService = role.guideScreens.find(
                (item: any) => item.step === 2,
            );
            setGuideSelectSalon(guideSalon ? guideSalon.guide : '');
            setGuideSelectService(guideService ? guideService.guide : '');
        }
    }, [role]);

    useEffect(() => {
       if(listChosenService?.length) {
              const isMoyoHair = listChosenService.find(
                (item: any) => item.serviceDeliveryType === 1 || item.serviceDeliveryType === 3,
              );
              setIsMoyoHairService(isMoyoHair ? true : false);
       }
    }, [listChosenService]);

    useEffect(() => {
        // Handle step 5 guide
        const listServices = `${selected[KEY.CHOSEN_SERVICES]
            .map((item: any) => item.serviceId)
            .join(';')};`;
        if (
            guideService &&
            guideService.length > 0 &&
            selected[KEY.CHOSEN_SERVICES].length > 0
        ) {
            const step5 = guideService.find((item: any) => item.step === 5);
            const guideList = step5.guideList.map((item: any) => {
                const splitItem = item.split('@@@');
                return {
                    regexString: splitItem[0],
                    message: splitItem[1],
                };
            });
            const guideString = guideList.find((item: any) => {
                const regex = new RegExp(item.regexString);
                return listServices.match(regex);
            });
            if (guideString) {
                setGuideStep5(guideString.message);
            }
        }
    }, [selected[KEY.CHOSEN_SERVICES]]);

    useEffect(() => {
        //số lượng người sử dụng dịch vụ = 1
        if(selected[KEY.GROUP_BOOKING] === 1) {
            onChangeSelected(KEY.HOUR, dfHour);
            changeCheckBox();
        } 
    }, [selected[KEY.DATE_BOOK], selected[KEY.STYLIST].id, selected[KEY.GROUP_BOOKING]]);


    return (
        <div className="main-screen">
            <SelectedSalon
                hasClickInSalon={hasClickInSalon}
                onChangeSelected={onChangeSelected}
                selected={selected}
                onChangeSalon={onChangeSalon}
                setIsSelectedSalonNear={setIsSelectedSalonNear}
                guideSelectSalon={guideSelectSalon}
            />
            <div className={`main-screen__block ${getClassHourStep('group')}`}>
                <div className="next-item" />
                <div className="block" id="service-time">
                    <SelectNumberGroupBooking
                        selected={selected}
                        onChangeSelected={onChangeSelected}
                        listSelected={listSelected}
                        setListSelected={setListSelected}
                    />
                </div>
            </div>
            {selected[KEY.GROUP_BOOKING] === 1 && (
                <>
                 <div className={`main-screen__block ${getClassHourStep('service')}`}>
                    <div className="next-item" />
                    <div className="block" id="service-time">
                        <Title text="2. Chọn dịch vụ:" />
    
                        <Service
                            isLoadingServices={isLoadingServices}
                            guideSelectService={guideSelectService}
                            onChangeSelected={onChangeSelected}
                            selected={selected}
                            setHasClickNextInSalon={setHasClickNextInSalon}
                            trackingData={trackingData}
                            hasClickInService={hasClickInService}
                            guideStep5={guideStep5}
                            allowChange={allowChange}
                        />
    
                    </div>
                </div>
                <div className={`main-screen__block ${getClassHourStep('hour')}`}>
                    <div className="next-item" />
                    <div className="block" id="service-time">
                       <>
                       <Title text="3. Chọn ngày, giờ & thợ cắt" />
                         {isMoyoHairService && (
                               <>
                               <Stylist
                                   onChangeSelected={onChangeSelected}
                                   moveSalonStylistbackUp={
                                       moveSalonStylistbackUp
                                   }
                                   dateBook={selected[KEY.DATE_BOOK]}
                                   customerPhone={selected[KEY.PHONE]}
                                   salon={selected[KEY.SALON]}
                                   listServices={selected[KEY.LIST_SERVICES]}
                                   isStylistBusy={isStylistBusy}
                                   stylistId={selected[KEY.STYLIST].id}
                                   trackingData={trackingData}
                                   availableHours={
                                       selected[KEY.AVAILABLE_HOURS]
                                   }
                                   chosenService={
                                       selected[KEY.CHOSEN_SERVICES]
                                   }
                                   hour={selected[KEY.HOUR]}
                                   userId={trackingData.customerId}
                                   reception={reception}
                                   setListSkinner={(data: any) =>
                                       setListSkinner(data)
                                   }
                                   changeServiceWithChangeStylistDateBook={
                                       changeServiceWithChangeStylistDateBook
                                   }
                                   stylitIdFromHistory={stylitIdFromHistory}
                               />
                           </>
                         )}
                       </>
                        <DateBook
                            onChangeDay={onChangeDay}
                            role={role}
                            dateBook={selected[KEY.DATE_BOOK]}
                            isSalonBusy={isSalonBusy}
                            isStylistBusy={isStylistBusy}
                            isLoadingHour={isLoadingHour}
                            salonId={selected[KEY.SALON].id}
                            setHasClickNextInSalon={setHasClickNextInSalon}
                            trackingData={trackingData}
                            chosenService={selected[KEY.CHOSEN_SERVICES]}
                            stylistId={selected[KEY.STYLIST].id}
                            setHasClickInService={setHasClickInService}
                            selected={selected}
                            setIsShow={setIsShowDateBook}
                            isShow={isShowDateBook}
                            isBookStylistMaster={isBookStylistMaster}
                            changeServiceWithChangeStylistDateBook={
                                changeServiceWithChangeStylistDateBook
                            }
                            allowChange={allowChange}
                            key={selected.index}
                    />
                    {
                        (selected[KEY.SALON].id > 0 &&
                            (selected[KEY.CHOSEN_SERVICES].length > 0 ||
                                allowChange) && (
                                <>
                                    <HoursSalonBackUp
                                        selected={selected}
                                        selectedSalonBackUp={selectedSalonBackUp}
                                        onChangeSelectedBackUp={
                                            onChangeSelectedBackUp
                                        }
                                        onChangeSelected={onChangeSelected}
                                        scrollToSelectDateBook={
                                            scrollToSelectDateBook
                                        }
                                        onChangeSalon={onChangeSalon}
                                    >
                                        <BoxTime
                                            setStylistBusy={setStylistBusy}
                                            isStylistBusy={isStylistBusy}
                                            role={role}
                                            selected={selected}
                                            setListIconHotTime={setListIconHotTime}
                                            onChangeSelected={onChangeSelected}
                                            hasClickNextStep={hasClickNextStep}
                                            trackingData={trackingData}
                                            userId={trackingData.customerId}
                                            setSalonBusy={setSalonBusy}
                                            isSalonBusy={isSalonBusy}
                                            setIsLoadingHour={setIsLoadingHour}
                                            isLoadingHour={isLoadingHour}
                                            isClickDateBook={isClickDateBook}
                                            setReception={setReception}
                                            allowChange={allowChange}
                                        />
                                    </HoursSalonBackUp>
                                </>
                            )
                        ) 
                    }
                    {(selected[KEY.HOUR].hour || selectedSalonBackUp.hour.hour) &&
                    selected[KEY.SALON].id > 0 &&
                    (selected[KEY.CHOSEN_SERVICES].length > 0 ||
                        allowChange) && (
                        <Extension
                            salonId={selected[KEY.SALON].id}
                            bookManyPeople={selected[KEY.SALON].bookManyPeople}
                            note={selected[KEY.NOTE]}
                            datebook={selected[KEY.DATE_BOOK]}
                            onChangeExtension={onChangeSelected}
                            trackingData={trackingData}
                            listServiceSelected={selected[KEY.CHOSEN_SERVICES]}
                            listSkinner={listSkinner}
                            skinnerId={selected[KEY.SKINNER_ID]}
                            selectConsultation={
                                selected[KEY.SALON]?.selectConsultation || false
                            }
                        />
                    )}
                    </div>
                </div>
                </>
            )}
            {selected[KEY.GROUP_BOOKING] >1  && (
                <BookingGroup
                    numberOfGroup={selected[KEY.GROUP_BOOKING]}
                    selected={selected}
                    listSelected={listSelected}
                    setListSelected={setListSelected}
                    onChangeSelected={onChangeSelected}
                    moveSalonStylistbackUp={moveSalonStylistbackUp}
                    changeServiceWithChangeStylistDateBook={
                        changeServiceWithChangeStylistDateBook
                    }
                    allowChange={allowChange}
                    stylitIdFromHistory={stylitIdFromHistory}
                    setHasClickNextInSalon={setHasClickNextInSalon}
                    selectedSalonBackUp={selectedSalonBackUp}
                    onChangeSelectedBackUp={
                        onChangeSelectedBackUp
                    }
                    setHasClickInService={setHasClickInService}
                    onChangeSalon={onChangeSalon}
                    hasClickNextStep={hasClickNextStep}
                    isBookStylistMaster={isBookStylistMaster}
                    ruleShowBooking={ruleShowBooking}
                    listCampaignTags={listCampaignTags}
                />
            )}

            <ModalSuggestService
                selected={selected}
                trackingData={trackingData}
                onChangeSelectedService={(value: any) =>
                    onChangeSelected(KEY.CHOSEN_SERVICES, value)
                }
                isClickNextInService={isClickNextInService}
            />
        </div>
    );
};

function mapStateToProps(state: any) {
    const { role, trackingData, user } = state;
    return { role, trackingData, userInfo: user };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setListIconHotTime: (value: any) => dispatch(setTimeSpecial(value)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainScreen);
