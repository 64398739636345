import React, { Fragment, useState, useEffect } from 'react';
import { Modal } from 'antd';
import { setTitleModalLogin, showModalLogin } from 'actions/common';
import { connect } from 'react-redux';
import { setBookingPhone } from 'actions/booking';
import FormLogin from '../form/FormLoginV2';
import close from '../../images/icons/close.svg';

const ModalLogin = (props) => {
    const [text, setText] = useState(props.modalLoginTitle);
    const onCancel = () => {
        props.setShowModalLogin(false);
        props.setModalLoginTitle('');
        props.setCustomerPhone('');
    };

    const onChangeText = (value) => {
        setText(value);
    };

    useEffect(() => {
        if (props.showModalLogin) {
            setTimeout(() => {
                if (document.body.style.width) {
                    document.getElementsByClassName(
                        'ant-modal-wrap',
                    )[0].style.width = document.body.style.width;
                }
            }, 0);
        }
    }, [props.showModalLogin]);

    return (
        <Modal
            className="modal-form-login"
            title={text}
            width={450}
            visible={props.showModalLogin}
            footer={null}
            maskClosable={false}
            destroyOnClose
            zIndex={1001}
            onCancel={onCancel}
            closeIcon={<img src={close} alt="" />}
        >
            {props.showModalLogin && (
                <Fragment>
                    <FormLogin
                        onCancel={onCancel}
                        onChangeText={onChangeText}
                    />
                </Fragment>
            )}
        </Modal>
    );
};

ModalLogin.defaultProps = {
    showModalLogin: false,
};

const mapStateToProps = (state) => {
    const { showModalLogin, modalLoginTitle } = state.common;
    const { userId } = state.user;
    return {
        showModalLogin,
        modalLoginTitle,
        userId,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setShowModalLogin: (isShow) => {
            dispatch(showModalLogin(isShow));
        },
        setModalLoginTitle: (title) => {
            dispatch(setTitleModalLogin(title));
        },
        setCustomerPhone: (value) => dispatch(setBookingPhone(value)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalLogin);
