enum KEY {
    SALON = 'salon',
    SALON_ID = 'salonId',
    SERVICES = 'servicesName',
    CHOSEN_SERVICES = 'chosenService',
    CHOSEN_SERVICES_WEEKEND = 'chosenServiceWeekend',
    PHONE = 'customerPhone',
    STYLIST = 'stylist',
    STYLIST_ID = 'stylistId',
    HOUR = 'hour',
    BOOK_HOUR_ID = 'bookHourId',
    SUB_HOUR_ID = 'subHourId',
    DATE_BOOK = 'dateBook',
    CAMPAIGN = 'campaignId',
    BOOK_AT = 'bookAt',
    MAP_SALON = 'maps',
    SALON_NEW = 'salonNew',
    SECOND_SUB_HOUR_ID = 'secondSubHourId',
    NOS = 'NoS',
    STEP = 'step',
    MAP = 'map',
    PARKING = 'parking',
    AVAILABLE_HOURS = 'availableHours',
    USER_ID = 'userId',
    NOTE = 'note',
    NOTE_STYLIST_MOVE_SALON = 'noteStylistMoveSalon',
    LIST_SERVICES = 'listServices',
    RECEIVE_ADVICE = 'receiveAdvice',
    UTILITY_SERVICE = 'utilityService',
    BOOK_WITH_CHILD = 'bookWithChild',
    ACCEPT_MAKE_PHOTO = 'acceptMakePhoto',
    LITTLE_ADVICE = 'littleAdvice',
    NUMBER_PERSONS = 'numberPersons',
    SKINNER_ID = 'skinnerId',
    CHOSEN_CAMPAIGN = 'chosenCampaign',
    LIST_ALL_SERVICES = 'listAllService',
    LIST_CAMPAIGN = 'listCampaign',
    GROUP_BOOKING = 'groupBooking',
    BOOK_HOURS = 'bookHours',
    BOOKING_GROUP_DETAILS = 'bookingGroupDetails',
    IS_CURRENT = 'isCurrent',
    INDEX= 'index',
}

export default KEY;
