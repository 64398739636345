import React, { FC, useEffect, useState }from "react";
import Title from "../components/Title";
import Service from "../Service";
import ServiceGroup from "../ServiceGroup";
import { ISelected, ISelectedSalonBackUp } from "pages/bookingNew/types/Selected";
import KEY from '../../Const';
import Stylist from "../Stylist";
import { scrollToSmoothly } from "utils/ScrollSmooth";
import DateBook from "../Datebook";
import HoursSalonBackUp from "../HoursSalonBackUp";
import BoxTime, { TypeBookHourSelected } from "../ListTime";
import Extension from "../Extensions";
import { setTimeSpecial } from "actions/booking";
import { connect } from "react-redux";
import moment from "moment";

const TODAY = moment(new Date()).format('DD-MM-YYYY');
const dfHour = {
    bookHourId: 0,
    subHourId: 0,
    secondSubHourId: 0,
    hour: '',
};

const BookingGroup: FC<{ 
    role: any;
    numberOfGroup: number;
    trackingData: any;
    selected: ISelected;
    onChangeSelected: Function;
    setListSelected: Function;
    listSelected: ISelected[];
    moveSalonStylistbackUp: Function;
    setListIconHotTime: Function;
    changeServiceWithChangeStylistDateBook: Function;
    allowChange: boolean;
    stylitIdFromHistory: number;
    setHasClickNextInSalon: Function;
    selectedSalonBackUp: ISelectedSalonBackUp;
    onChangeSelectedBackUp: Function;
    setHasClickInService: Function;
    onChangeSalon: Function;
    hasClickNextStep: number;
    isBookStylistMaster: boolean;
    ruleShowBooking: any;
    listCampaignTags: CampaignServiceTagType[];
    // listDataChosenGroup: any;
    // setListDataChosenGroup: Function;
 }> = ({ 
    role,
    numberOfGroup, 
    trackingData,
    // listDataChosenGroup,
    // setListDataChosenGroup,
    selected,
    onChangeSelected,
    setListSelected,
    listSelected,
    moveSalonStylistbackUp,
    setListIconHotTime,
    changeServiceWithChangeStylistDateBook,
    allowChange,
    stylitIdFromHistory,
    setHasClickNextInSalon,
    selectedSalonBackUp,
    onChangeSelectedBackUp,
    setHasClickInService,
    onChangeSalon,
    hasClickNextStep,
    isBookStylistMaster,
    ruleShowBooking,
    listCampaignTags
}) => {
    const [guideSelectSalon, setGuideSelectSalon] = useState('');
    const [guideSelectService, setGuideSelectService] = useState('');
    const [guideStep5, setGuideStep5] = useState('');
    const [isStylistBusy, setStylistBusy] = useState<number>(1);
    const [isSalonBusy, setSalonBusy] = useState<number>(1);
    const [isLoadingHour, setIsLoadingHour] = useState<boolean>(true);
    const [isClickDateBook, setIsClickDateBook] = useState<boolean>(false);
    const [isShowDateBook, setIsShowDateBook] = useState<boolean>(false);
    const [reception, setReception] = useState({
        avatar: '',
        name: '',
    });
    const [listSkinner, setListSkinner] = useState([]);
    const listChosenService = selected[KEY.CHOSEN_SERVICES];
    const [isMoyoHairService, setIsMoyoHairService] = useState(false);
    const [listDataChosenGroup, setListDataChosenGroup] = useState<ISelected[]>([]);

    const scrollToSelectDateBook = () => {
        const element = document.querySelector('#datebookId') as HTMLDivElement;
        if (element) {
            const elementPosition = element.getBoundingClientRect().top;
            const pos = elementPosition || 0;
            scrollToSmoothly(pos, 300);
        }
        if (!isShowDateBook) {
            setIsShowDateBook(true);
        }
    };

    function onChangeDay(item: any, indexItem?: number) {
        if(indexItem === 0) {
            listSelected.forEach((i, index) => {
               setTimeout(() => {
                    onChangeSelected(KEY.DATE_BOOK, item?.day ? item.day : TODAY, index); 
               }, 100 * index);
            });
        } else {
            onChangeSelected(KEY.DATE_BOOK, item?.day ? item.day : TODAY, indexItem);
        }
        setIsClickDateBook(true);
    }

    function getClassHourStep(index: number) {
        if(index == 0) {
            const firstItem = listSelected[0];
            if(firstItem?.chosenService?.length && firstItem?.bookHours?.length) {
                return  'main-screen__block--done';
              
            } else {
                return 'main-screen__block--active'
            }
        } else {
            const item = listSelected[index];
            const previousItem = listSelected[index -1];
            //nếu từ item thứ 2 trở đi, sau khi item trước đã chọn
            if(previousItem?.chosenService?.length && previousItem?.bookHours?.length) {
                if(item?.chosenService?.length && item?.bookHours?.length) {
                    return  'main-screen__block--done';
                }
                else
                return 'main-screen__block--active'
            } else {
                return ''
            }
        }
        
        return '';
    }

    useEffect(() => {
        if (role.guideScreens && role.guideScreens.length > 0) {
            const guideSalon = role.guideScreens.find(
                (item: any) => item.step === 1,
            );
            const guideService = role.guideScreens.find(
                (item: any) => item.step === 2,
            );
            setGuideSelectSalon(guideSalon ? guideSalon.guide : '');
            setGuideSelectService(guideService ? guideService.guide : '');
        }
    }, [role]);

    const salonId = selected[KEY.SALON].id;
    function getServiceDeliveryType(serviceDeliveryType: number[]) {
            if (serviceDeliveryType.includes(2) && serviceDeliveryType.length === 1) return 2;
            if (serviceDeliveryType.includes(1) && serviceDeliveryType.length === 1) return 1;
            return 3;
        }
    function filterAndCount(A: any[], serviceDeliveryTypeSelected: number) {
            // Bước 1: Lọc các item có serviceDeliveryType = serviceDeliveryTypeSelected
            const filteredItems = A.filter(item => item.serviceDeliveryType === serviceDeliveryTypeSelected);
            // Bước 2: Đếm số lần xuất hiện của mỗi hourId
            const countMap = filteredItems.reduce((acc, item) => {
                acc[item.subHourId] = (acc[item.subHourId] || 0) + 1;
                return acc;
            }, {});
            
            // Bước 3: Chuyển countMap thành mảng kết quả
            return Object.keys(countMap).map(hourId => ({
                
                bookHourId: Number(hourId),
                // subHourId: Number(subHourId),
                count: countMap[hourId],
                // serviceType:
            }));
        }

        const [listCountHourId, setListCountHourId] = useState<TypeBookHourSelected[]>([]);
        
        const [serviceTypeChosen, setServiceTypeChosen] = useState<number | undefined>(undefined);
    
        const checkDuplicateServiceDelivery = () => {
            //lấy index của item đang chọn
            const indexSelected = selected[KEY.INDEX];
            //lấy ra list serviceDeliveryType của các dịch vụ item đã chọn
            const listServiceDeliveryTypeSelected = selected[KEY.CHOSEN_SERVICES].map(item => item.serviceDeliveryType);
            // lấy ra list serviceDeliveryType của các dịch vụ item đã chọn và loại bỏ các giá trị trùng nhau
            const listServiceDeliveryTypeSelectedDistinct = listServiceDeliveryTypeSelected.filter((item, index) => listServiceDeliveryTypeSelected.indexOf(item) === index);
            const serviceDeliveryTypeSelected = getServiceDeliveryType(listServiceDeliveryTypeSelectedDistinct);
            setServiceTypeChosen(serviceDeliveryTypeSelected);
            const listSelectedWithoutCurrent = listSelected?.filter((item, index) => index !== indexSelected);
            if(listSelected?.length) {
                const listServiceDelivery = [];
                for (let i = 0; i < listSelected.length; i++) {
                    const chosenServices = listSelected[i][KEY.CHOSEN_SERVICES];
                    const serviceDeliveryTypeChs = chosenServices.map(service => service.serviceDeliveryType);
                    const listServiceDeliveryTypeChsDistinct = serviceDeliveryTypeChs.filter((item, index) => serviceDeliveryTypeChs.indexOf(item) === index)
                    listServiceDelivery.push({
                        serviceDeliveryType: getServiceDeliveryType(listServiceDeliveryTypeChsDistinct), // Lấy tất cả serviceDeliveryType vào mảng
                        hourId: listSelected[i][KEY.HOUR].bookHourId,
                        subHourId: listSelected[i][KEY.HOUR].subHourId,
                        index: listSelected[i][KEY.INDEX]
                    });
                }
               const hihi = filterAndCount(listServiceDelivery, serviceDeliveryTypeSelected);
               setListCountHourId(hihi);
            }
        }



        useEffect(() => {
            if(listSelected && listSelected?.length > 1) {
                checkDuplicateServiceDelivery();
            }
        }, [listSelected])

    return (
        <div>
            {listSelected.length > 0 ?
             listSelected.map((item, index) => 
             { 
                const isMoyoHairService = item[KEY.CHOSEN_SERVICES].find(
                    (item: any) => item.serviceDeliveryType === 1 || item.serviceDeliveryType === 3,
                  ) ? true : false;
                
                return (
                    <div key={index} className={`main-screen__block ${getClassHourStep(index)}`}>
                    <div className="next-item" />
                    <div className="block" id="service-time">
                        <Title text={`Chọn dịch vụ khách hàng ${index + 1}`} />
                        <ServiceGroup
                            isLoadingServices={false}
                            onChangeSelected={onChangeSelected}
                            selected={item}
                            index={index}
                            trackingData={trackingData}
                            allowChange={true}
                            isBookingGroup={true}
                            salonId={salonId}
                            ruleShowBooking={ruleShowBooking}
                            listCampaignTags={listCampaignTags}
                            listSelected={listSelected}

                        />
                        {/* <Title text="Chọn ngày, giờ & thợ cắt" /> */}
                        {isMoyoHairService && (
                            <Stylist
                                onChangeSelected={onChangeSelected}
                                moveSalonStylistbackUp={moveSalonStylistbackUp}
                                dateBook={item[KEY.DATE_BOOK]}
                                customerPhone={item[KEY.PHONE]}
                                salon={item[KEY.SALON]}
                                listServices={item[KEY.LIST_SERVICES]}
                                isStylistBusy={isStylistBusy}
                                stylistId={item[KEY.STYLIST].id}
                                trackingData={trackingData}
                                availableHours={item[KEY.AVAILABLE_HOURS]}
                                chosenService={item[KEY.CHOSEN_SERVICES]}
                                hour={item[KEY.HOUR]}
                                userId={trackingData.customerId}
                                reception={reception}
                                setListSkinner={setListSkinner}
                                changeServiceWithChangeStylistDateBook={changeServiceWithChangeStylistDateBook}
                                stylitIdFromHistory={stylitIdFromHistory}
                            />
                        )}
                        
                        <DateBook
                            onChangeDay={onChangeDay}
                            role={role}
                            dateBook={item[KEY.DATE_BOOK]}
                            isSalonBusy={isSalonBusy}
                            isStylistBusy={isStylistBusy}
                            isLoadingHour={isLoadingHour}
                            salonId={item[KEY.SALON].id}
                            setHasClickNextInSalon={setHasClickNextInSalon}
                            trackingData={trackingData}
                            chosenService={item[KEY.CHOSEN_SERVICES]}
                            stylistId={item[KEY.STYLIST].id}
                            setHasClickInService={setHasClickInService}
                            selected={item}
                            // setIsShow={setIsShowDateBook}
                            // isShow={isShowDateBook}
                            isBookStylistMaster={isBookStylistMaster}
                            changeServiceWithChangeStylistDateBook={changeServiceWithChangeStylistDateBook}
                            allowChange={allowChange}
                            indexItem={index}
                        />
                            {index !== 0 && listSelected[index - 1][KEY.CHOSEN_SERVICES]?.length > 0 && item[KEY.CHOSEN_SERVICES]?.length === 0 && (
                               <div style={{borderWidth: 1, display: 'inline-block', padding: '4px 8px', borderRadius: 10, cursor: 'pointer'}} className="block mt-4" onClick={() => onChangeSelected(KEY.CHOSEN_SERVICES, listSelected[0][KEY.CHOSEN_SERVICES], index)}>
                                    <div className="text-sm text-gray-700">
                                            Giống khách hàng 1: {listSelected[0][KEY.CHOSEN_SERVICES]
                                                .map(service => service?.name)
                                                .join(', ')}
                                        </div>
                               </div>
                              
                            )}
                        
                        {(item[KEY.SALON].id > 0 && (item[KEY.CHOSEN_SERVICES].length > 0 || allowChange)) && (
                            <HoursSalonBackUp
                                selected={item}
                                selectedSalonBackUp={selectedSalonBackUp}
                                onChangeSelectedBackUp={onChangeSelectedBackUp}
                                onChangeSelected={onChangeSelected}
                                scrollToSelectDateBook={scrollToSelectDateBook}
                                onChangeSalon={onChangeSalon}
                            >
                                <BoxTime
                                    setStylistBusy={setStylistBusy}
                                    isStylistBusy={isStylistBusy}
                                    role={role}
                                    selected={item}
                                    setListIconHotTime={setListIconHotTime}
                                    onChangeSelected={onChangeSelected}
                                    hasClickNextStep={hasClickNextStep}
                                    trackingData={trackingData}
                                    userId={trackingData.customerId}
                                    setSalonBusy={setSalonBusy}
                                    isSalonBusy={isSalonBusy}
                                    setIsLoadingHour={setIsLoadingHour}
                                    isLoadingHour={isLoadingHour}
                                    isClickDateBook={isClickDateBook}
                                    setReception={setReception}
                                    allowChange={allowChange}
                                    listSelected={listSelected}
                                    listCountHourId={listCountHourId}
                                    serviceTypeChosen={serviceTypeChosen}
                                />
                            </HoursSalonBackUp>
                        )}
                        
                        {((item?.index === listSelected?.length -1) && item?.chosenService?.length && item?.bookHours?.length) ? (
                            <Extension
                                salonId={item[KEY.SALON].id}
                                bookManyPeople={item[KEY.SALON].bookManyPeople}
                                note={listSelected[0][KEY.NOTE]}
                                datebook={item[KEY.DATE_BOOK]}
                                onChangeExtension={onChangeSelected}
                                listSelected={listSelected}
                                trackingData={trackingData}
                                listServiceSelected={item[KEY.CHOSEN_SERVICES]}
                                listSkinner={listSkinner}
                                skinnerId={item[KEY.SKINNER_ID]}
                                selectConsultation={item[KEY.SALON]?.selectConsultation || false}
                            />
                        ) : null}
                    </div>
                </div> 
                )
             }
            ) : <div/>}

        </div>
    );
};

function mapStateToProps(state: any) {
    const { role, trackingData, user } = state;
    return { role, trackingData, userInfo: user };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setListIconHotTime: (value: any) => dispatch(setTimeSpecial(value)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(BookingGroup);
 