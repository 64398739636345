import React, { FC, memo, useEffect, useState } from 'react';
import API from 'repository';
import { useSelector } from 'react-redux';
import trackingConst from 'config/TrackingConst';
import { Checkbox } from 'antd';
import useScrollDir from 'hooks/useScrollDir';
import { formatPriceWithKCharacter } from 'utils/BookingUltils';
import { ISelected } from '../../types/Selected';
import CampaignFooter from './CampaignFooter';
import TotalServiceSelected from './TotalServiceSelected';
import KEY from '../../Const';
import { IService } from 'pages/bookingNew/types/Service';

const ButtonFooter: FC<{
    gotoCampaignScreen: Function;
    onChangeStep: Function;
    dataBooking: ISelected;
    onSelectService: Function;
    allowChange: boolean;
    setAllowChange: Function;
    gotoCampaignDetail: Function;
    isBookingGroup?: boolean;
    onHandleSelectService?: Function;
    serviceSelected?: IService[];
    setIsCampaignScreen?: Function;
}> = memo(
    ({
        onChangeStep,
        dataBooking,
        gotoCampaignScreen,
        onSelectService,
        allowChange,
        setAllowChange,
        gotoCampaignDetail,
        isBookingGroup,
        onHandleSelectService,
        serviceSelected,
        setIsCampaignScreen
    }) => {
        const common = useSelector((state: any) => state.common);
        const trackingData = useSelector((state: any) => state.trackingData);

        const [isShowSelectedService, setIsShowSelectedService] = useState(
            false,
        );
        const [totalMoneyOldSelected, setTotalMoneyOldSelected] = useState(0);
        const [totalMoneySelected, setTotalMoneySelected] = useState(0);
        const scrollDir = useScrollDir();
        const serviceCount = isBookingGroup ? serviceSelected?.length ?? 0 : dataBooking.chosenService.length;
        const serviceChosen = isBookingGroup ? serviceSelected ?? [] : dataBooking.chosenService;

        async function postTracking(data: any) {
            try {
                await API.postTrackingDynamic(data);
            } catch (e) {
                console.log(e.message);
            }
        }

        const active: boolean =
            serviceChosen?.length !== 0 || allowChange;

        const handleChangeStep = async () => {
            if(isBookingGroup) {
                onHandleSelectService && onHandleSelectService();
            } else {
                if (active) onChangeStep(0, dataBooking[KEY.INDEX]);
            }
            

            const dataTracking = {
                pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
                screenId: trackingConst.Screens.BOOKING_SELECT_SERVICE_SCREEN,
                eventId: trackingConst.Events.CLICK_BUTTON_SERVICE,
                tokenKey: trackingData.token,
                source: trackingData.source,
                utmSource: trackingData.utmSource,
                data: {
                    phone: dataBooking[KEY.PHONE],
                    salonId: dataBooking[KEY.SALON].id.toString(),
                    dateBook: dataBooking[KEY.DATE_BOOK],
                    stylistId: dataBooking[KEY.STYLIST].id.toString(),
                    hoursAvailable: dataBooking[KEY.AVAILABLE_HOURS].toString(),
                    chosenServices: JSON.stringify(
                        dataBooking[KEY.CHOSEN_SERVICES],
                    ),
                    hourBook: dataBooking[KEY.HOUR].hour,
                    step: dataBooking[KEY.STEP],
                },
            };

            await postTracking(dataTracking);
        };

        useEffect(() => {
            if (serviceChosen?.length) {
                setTotalMoneySelected(
                    serviceChosen.reduce(
                        (acc, curr) => acc + curr.priceNum,
                        0,
                    ),
                );
                setTotalMoneyOldSelected(
                    serviceChosen.reduce(
                        (acc, curr) => acc + curr.oldPriceNum,
                        0,
                    ),
                );
            } else {
                setTotalMoneySelected(0);
                setTotalMoneyOldSelected(0);
            }
        }, [serviceChosen]);

        useEffect(() => {
            if (scrollDir === 'down') {
                setIsShowSelectedService(false);
            }
        }, [scrollDir]);

        return (
            <>
                <div
                    className={`button-affix ${
                        scrollDir === 'down'
                            ? ''
                            : 'shadow-[0px_-2px_10px_0px_rgba(0,0,0,0.1)]'
                    }  overflow-hidden`}
                >
                    <TotalServiceSelected
                        show={isShowSelectedService}
                        onClose={() => setIsShowSelectedService(false)}
                        chosenService={serviceChosen}
                        onSelectService={onSelectService}
                    />
                    <div
                        className={`relative w-full z-[9] `}
                    >
                        {dataBooking.salon?.selectConsultation ? (
                            <div
                                className="sevice-allow-change bg-white px-[13px] cursor-pointer"
                                onClick={() => setAllowChange(!allowChange)}
                                aria-hidden="true"
                            >
                                <div className="flex gap-2.5 py-2 border-b border-[#E8E8E8]">
                                    <Checkbox checked={allowChange} />
                                    <div>
                                        <div className="text-[#20489B] text-sm font-semibold">
                                            {common.lang === 'vi'
                                                ? 'Bạn không biết chọn dịch vụ gì!'
                                                : 'Don’t know which service to choose'}
                                        </div>
                                        <div className="text-[#5F5F5F] text-xs">
                                            {common.lang === 'vi'
                                                ? 'Nhân viên sẽ giúp bạn chọn dịch vụ tại cửa hàng'
                                                : 'Our staff will help you select services at salon'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        <CampaignFooter
                            gotoCampaignScreen={() => gotoCampaignScreen()}
                            chosenCampaign={dataBooking.chosenCampaign}
                            chosenService={dataBooking.chosenService}
                            phoneBooking={dataBooking.customerPhone}
                            gotoCampaignDetail={gotoCampaignDetail}
                            setIsCampaignScreen={setIsCampaignScreen}
                            isBookingGroup={isBookingGroup}
                        />
                    </div>
                    <div className="flex items-center space-between px-4 pt-3 relative z-[10] bg-white pb-3">
                        <div
                            className="underline text-[#20489B] text-sm cursor-pointer"
                            onClick={() => {
                                if (serviceChosen.length)
                                    setIsShowSelectedService(
                                        !isShowSelectedService,
                                    );
                            }}
                            role="presentation"
                        >
                            {common.lang === 'vi'
                                ? `Đã chọn ${serviceCount} dịch vụ`
                                : `Selected ${
                                    serviceCount
                                  } ${
                                  serviceCount < 2
                                          ? 'service'
                                          : 'services'
                                  }`}
                        </div>
                        <div className="text-right ml-auto text-sm pr-2.5">
                            {common.lang === 'vi' ? 'Tổng thanh toán' : 'Cost'}
                            <div className="flex items-center justify-end gap-1">
                                {totalMoneyOldSelected !==
                                totalMoneySelected ? (
                                    <div className="font-semibold line-through text-[#A3A3A3] text-sm text-right">
                                        {formatPriceWithKCharacter(
                                            totalMoneyOldSelected,
                                        )}
                                    </div>
                                ) : null}
                                <div className="text-lg font-semibold text-right">
                                    {formatPriceWithKCharacter(
                                        totalMoneySelected,
                                    )}
                                </div>
                            </div>
                        </div>
                        <div
                            className={`text-base font-semibold py-[13px] px-[18px] rounded-[5px] ${
                                active
                                    ? 'bg-[#20489B] text-white hover:bg-[#F0F8FF] hover:text-[#20489B] cursor-pointer'
                                    : 'bg-[#E8E8E8] text-[#767676]'
                            }`}
                            onClick={() => handleChangeStep()}
                            role="presentation"
                        >
                            <span>
                                {common.lang === 'vi' ? 'Xong' : 'Done'}
                            </span>
                        </div>
                    </div>
                </div>
            </>
        );
    },
);

export default ButtonFooter;
