import React, { FC, useState } from 'react';
// import { LoadingOutlined } from '@ant-design/icons';

const CommonImage: FC<{ src: string; className: string }> = ({
  src,
  className,
}) => {
  const [isImageError, setIsImageError] = useState(false);
  const handleImageError = (event: any) => {
    console.error('Lỗi khi tải ảnh:', event);
    setIsImageError(true);
  };

  return (
    <>
        {src && !isImageError ? (
          <img
            className={className}
            src={src}
            alt="Ảnh"
            onError={handleImageError}
          />
        ) : (
          <img
            src="/moyo-chon-ho-ban.svg"
            className={className}
            style={{ borderWidth: '2px', borderColor: '#E8E8E8' }}
            alt="Ảnh mặc định"
          />
        )}
      </>
    );
};

export default CommonImage;
