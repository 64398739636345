/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, useState, useEffect } from 'react';
import API from 'repository';
import { connect } from 'react-redux';
import trackingConst from 'config/TrackingConst';
import iconStylist from 'images/fillGray/user-large.svg';
import iconArrowRight from 'images/fillGray/chevronRight.svg';
import iconArrowDown from 'images/fillGray/chevronDown.svg';
import ModalStylistMoveSalon from './components/ModalStylistMoveSalon';
import KEY from '../../Const';
import {
    IStylist,
    IDataModal,
    IParamsGetStylist,
    IStylistSelected,
} from '../../types/Stylist';
import ListStylist from './components/ListStylist';
import StylistBusyModal from './components/ModalStylistBusy';

import './styles.scss';
import DetailStylist from './components/DetailStylist';
import ListStylistV2 from './components/ListStylistV2';

const Stylist: FC<{
    onChangeSelected: Function;
    moveSalonStylistbackUp: Function;
    [KEY.SALON]: {
        id: number;
        name: string;
    };
    [KEY.AVAILABLE_HOURS]: number;
    [KEY.DATE_BOOK]: string;
    [KEY.PHONE]: string;
    [KEY.CHOSEN_SERVICES]?: any;
    isStylistBusy: number;
    stylistId: number;
    listSalon: any;
    reception: any;
    [KEY.HOUR]: any;
    role: any;
    trackingData: any;
    userId: any;
    setListSkinner: Function;
    changeServiceWithChangeStylistDateBook: Function;
    listServices: any;
    stylitIdFromHistory: number;
}> = ({
    onChangeSelected,
    moveSalonStylistbackUp,
    salon,
    availableHours,
    dateBook,
    customerPhone,
    chosenService,
    isStylistBusy,
    stylistId,
    listSalon,
    reception,
    hour,
    trackingData,
    userId,
    role,
    setListSkinner,
    changeServiceWithChangeStylistDateBook,
    listServices,
    stylitIdFromHistory,
}) => {
    const initialStylist = {
        staffId: 0,
        avatar: '',
        staffName: '',
        special: false,
        salonEnroll: 0,
        favoriteImages: '',
        experience: 0,
        totalCutomer: 0,
        ratingStar: 0,
        star: 0,
        description: '',
        skill: 0,
        skillLevel: 0,
        ratingInfor: '',
    };
    const date = dateBook;
    const [dataStylistMoveSalon, setDataStylistMoveSalon] = useState<
        IDataModal
    >({
        [KEY.STYLIST]: initialStylist,
        [KEY.SALON]: {
            id: 0,
            name: '',
        },
    });
    const [listStylist, setListStylist] = useState<IStylist[]>([]);
    const [stylist, setStylist] = useState<IStylist>({
        ...initialStylist,
        staffId: stylistId,
    });
    const [
        visiblePopupStylistMoveSalon,
        setVisiblePopupStylistMoveSalon,
    ] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isExpandStylist, setIsExpandStylist] = useState(false);

    const postTracking = async (data: any) => {
        try {
            API.postTrackingDynamic(data);
        } catch (e) {
            console.log(e.message);
        }
    };

    const checkStylist = (list: IStylist[]) => {
        if (stylistId !== 0) {
            const stylistSelected = list.find(
                (item: any) => item.staffId === stylistId,
            );
            if (!stylistSelected) {
                setStylist(initialStylist);
            } else {
                setStylist(stylistSelected);
            }
        }
    };

    const getAllStyList = async (params: IParamsGetStylist) => {
        setIsLoading(true);
        try {
            const response: any = await API.getAllStyListV4(params);
            if (response?.data?.stylists) {
                if (stylist.staffId) checkStylist(response.data.stylists);
                setListStylist(response.data.stylists);
            }
            if (response?.data?.skinners) {
                setListSkinner(response.data?.skinners);
            } else {
                setListSkinner([]);
            }
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    };
    const onClickItem = (value: IStylist) => {
        const dataTracking = {
            pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
            screenId:
                trackingConst.Screens.BOOKING_SELECT_BOOKING_SINGLE_SCREEN,
            eventId: trackingConst.Events.SHOW_POPUP_STYLIST_MOVE_SALON,
            tokenKey: trackingData.token,
            source: trackingData.source,
            utmSource: trackingData.utmSource,
            data: {
                phone: customerPhone,
                salonId: salon.id.toString(),
                chosenServices: JSON.stringify(chosenService),
                dateBook,
                stylistId: value?.staffId ? value?.staffId?.toString() : '0',
                customerId: userId || 0,
            },
        };
        if (value.salonEnroll === salon.id || value.staffId === 0) {
            setStylist(value);
            onChangeSelected(KEY.NOTE_STYLIST_MOVE_SALON, '');
            postTracking({
                ...dataTracking,
                eventId: value.special
                    ? trackingConst.Events.CLICK_SELECT_OLD_STYLIST
                    : trackingConst.Events.CLICK_SELECT_STYLIST,
            });
        } else {
            const salonStylistMove = listSalon.find(
                (item: any) => item.id === value.salonEnroll,
            );
            setDataStylistMoveSalon({
                [KEY.STYLIST]: value,
                [KEY.SALON]: salonStylistMove || {
                    id: 0,
                    name: '',
                },
            });
            setVisiblePopupStylistMoveSalon(true);
            postTracking(dataTracking);
        }
    };

    const handleToggleStylist = () => {
        // tracking
        const dataTracking = {
            pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
            screenId:
                trackingConst.Screens.BOOKING_SELECT_BOOKING_SINGLE_SCREEN,
            eventId: isExpandStylist
                ? trackingConst.Events.CLICK_CLOSE_STYLIST
                : trackingConst.Events.CLICK_SHOW_STYLIST,
            tokenKey: trackingData.token,
            source: trackingData.source,
            utmSource: trackingData.utmSource,
            data: {
                phone: customerPhone,
                salonId: salon.id.toString(),
                chosenServices: JSON.stringify(chosenService),
                dateBook: typeof dateBook === 'string' ? dateBook : '',
                stylistId: stylist?.staffId ? stylist.staffId.toString() : '0',
                customerId: userId || 0,
            },
        };
        postTracking(dataTracking);
        setIsExpandStylist(!isExpandStylist);
    };

    const trackingPopupStylistMoveSalon = (
        isCancel: boolean,
        salonIdMove: number,
    ) => {
        const dataTracking = {
            pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
            screenId:
                trackingConst.Screens.BOOKING_SELECT_BOOKING_SINGLE_SCREEN,
            eventId: isCancel
                ? trackingConst.Events.CLOSE_POPUP_STYLIST_MOVE_SALON
                : trackingConst.Events.CLICK_STYLIST_MOVE_SALON,
            tokenKey: trackingData.token,
            source: trackingData.source,
            utmSource: trackingData.utmSource,
            data: {
                phone: customerPhone,
                salonId: salon.id.toString(),
                chosenServices: JSON.stringify([KEY.CHOSEN_SERVICES]),
                dateBook,
                stylistId: stylist?.staffId ? stylist.staffId.toString() : '0',
                customerId: userId || 0,
                salonIdMove: salonIdMove.toString(),
            },
        };
        postTracking(dataTracking);
    };

    const onHandleMoveSalonStylist = () => {
        const stylistSelected: IStylistSelected = {
            // @ts-ignore
            id: dataStylistMoveSalon.stylist.staffId,
            // @ts-ignore
            name: dataStylistMoveSalon.stylist.staffName,
            skillLevel: dataStylistMoveSalon.stylist.skillLevel,
        };
        setStylist(dataStylistMoveSalon.stylist);
        setVisiblePopupStylistMoveSalon(false);
        moveSalonStylistbackUp({
            salon: dataStylistMoveSalon.salon,
            stylist: stylistSelected,
        });
        trackingPopupStylistMoveSalon(false, dataStylistMoveSalon.salon.id);
    };

    const onCancelPopupMoveSalonStylist = () => {
        setVisiblePopupStylistMoveSalon(false);
        onChangeSelected(KEY.STYLIST, { id: 0, name: '' });
        onChangeSelected(
            KEY.NOTE_STYLIST_MOVE_SALON,
            'Hệ thống hãy chọn cho tôi thợ tốt nhất',
        );
        trackingPopupStylistMoveSalon(true, dataStylistMoveSalon.salon.id);
    };

    useEffect(() => {
        const params = {
            salonId: salon.id,
            workDate: date,
            customerPhone,
        };
        if (salon.id > 0) {
            getAllStyList(params);
        }
    }, [salon.id, dateBook]);
    useEffect(() => {
        const stylistSelected: IStylistSelected = {
            // @ts-ignore
            id: stylist.staffId,
            // @ts-ignore
            name: stylist.staffName,
            skillLevel: stylist.skillLevel,
        };
        onChangeSelected(KEY.STYLIST, stylistSelected);
        changeServiceWithChangeStylistDateBook(
            listServices,
            chosenService,
            dateBook,
            stylistSelected,
        );
    }, [stylist]);

    return (
        <>
            <div className="stylist" id="stylist">
                <div
                    className="flex stylist__dropdown pointer"
                    onClick={handleToggleStylist}
                    role="presentation"
                >
                    <span className="flex">
                        <img src={iconStylist} alt="Moyo stylist" />
                    </span>
                    {stylist.staffName ? (
                        <span>{stylist.staffName}</span>
                    ) : (
                        <span>Chọn Stylist</span>
                    )}
                    <span className="flex item-center">
                        <img
                            src={
                                isExpandStylist ? iconArrowDown : iconArrowRight
                            }
                            alt=""
                        />
                    </span>
                </div>
                <DetailStylist
                    isLoading={isLoading}
                    isShowStylist={isExpandStylist}
                    stylistSelected={stylist}
                />
                <ListStylistV2
                    listStylist={listStylist}
                    onClickItem={onClickItem}
                    // @ts-ignore
                    id={stylist.staffId}
                    isLoading={isLoading}
                    allowBookStylist={role.allowBookStylist}
                    isStylistBusy={isStylistBusy}
                    salonId={salon.id}
                    isShowStylist={isExpandStylist}
                    stylitIdFromHistory={stylitIdFromHistory}
                />
            </div>
            {visiblePopupStylistMoveSalon && (
                <ModalStylistMoveSalon
                    onCancel={onCancelPopupMoveSalonStylist}
                    visible={visiblePopupStylistMoveSalon}
                    onHandleMoveSalonStylist={onHandleMoveSalonStylist}
                    stylist={dataStylistMoveSalon.stylist}
                    salonMove={dataStylistMoveSalon.salon}
                />
            )}
            <StylistBusyModal
                dateBook={dateBook}
                count={isStylistBusy}
                phone={customerPhone}
                salonId={salon.id}
                // @ts-ignore
                stylistId={stylist.staffId}
                hoursAvailable={availableHours.toString()}
                chosenServices={chosenService}
                hourBook={hour}
            />
        </>
    );
};

const mapStateToProps = (state: any) => {
    const { role } = state;
    return { role, listSalon: state.salon.listSalon };
};

export default connect(mapStateToProps)(Stylist);
