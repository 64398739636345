import React, { FC } from 'react';
import moment from 'moment';
import 'moment/locale/vi';
import { ITimeBox } from '../../../types/DateTime';
import { useSelector } from 'react-redux';
import { TypeBookHourSelected } from '..';
import { ISelected } from 'pages/bookingNew/types/Selected';

const ItemBoxTime: FC<{
    bookingDate: string;
    timeBox: ITimeBox;
    isCheck: boolean;
    onChangeTime: Function;
    onHandleExpiredTime: Function;
    isHighLight: Boolean;
    grHour: any;
    serviceTypeChosen?: number; 
    isFreeBook?: boolean;
    indexGr: number;
    listSelected?: ISelected[];
}> = ({
    bookingDate,
    timeBox,
    isCheck,
    onChangeTime,
    onHandleExpiredTime,
    isHighLight,
    grHour,
    isFreeBook,
    indexGr,
    listSelected,
}) => {
    // const isHourIdSelected = listCountHourId.find(item => item?.bookHourId === timeBox.subHourId);
    // const countHourId = isHourIdSelected?.count ?? 0; // Đặt mặc định là 0 nếu undefined
    
    // let isFree: boolean = true;
    
    // // @ts-ignore
    // let totalEmptySkinner = timeBox?.totalSkinnerEmpty ?? 0;
    // let totalStylistEmpty = timeBox?.totalStylistEmpty ?? 0;
    
    // // const decrement = 1 + countHourId;
    
    // if (serviceTypeChosen === 3) {
    //     isFree = (totalEmptySkinner - countHourId > 0) && (totalStylistEmpty - countHourId > 0);
    // } else if (serviceTypeChosen === 2) {
    //     isFree = totalEmptySkinner - countHourId > 0;
    // } else if (serviceTypeChosen === 1) {
    //     isFree = totalStylistEmpty - countHourId > 0;
    // }
    // const isFree = (timeBox?.totalEmpty ?? 0) - countHourId > 0;
    
    const wapperClass = `box-time_item ${
        (!timeBox.isFree  || !isFreeBook) ? 'unavailable' : ''
    } ${isCheck ? 'has-choice' : ''} ${
        isHighLight ? 'time-box-high-light' : ''
    }`;

    const trackingData = useSelector((state: any) => state.trackingData);

    const timeInBox = moment(bookingDate, 'DD-MM-YYYY')
        .add(parseInt(timeBox.hour.split('h')[0]), 'hours')
        .add(parseInt(timeBox.hour.split('h')[1]), 'minutes');

    const isExpiredTime =
        timeBox.isFree && timeInBox.isBefore(moment(new Date()));

    const handleChangeTime = () => {
        const isTimeOpenBookAtSalon =
            trackingData.utmSource === 'book_at_salon' && timeBox.isFree;
        if(!isFreeBook) {
            return;
        }
        if (!isExpiredTime || isTimeOpenBookAtSalon) {
            onChangeTime(timeBox, grHour);
        } else {
            onHandleExpiredTime(timeBox.secondSubHourId);
        }
    };

    return (
        <>
         {listSelected && listSelected?.length > 1 && isCheck && (
                <div style={{backgroundColor: '#1877F2', width: 20, height: 20, borderRadius: 10, position: 'absolute', fontSize: 14, color: 'white', textAlign: 'center', right: 0}}>
                    {indexGr + 1}
                </div>
            )}
             <div
            className={wapperClass}
            onClick={handleChangeTime}
            role="presentation"
        >
            {timeBox.hour}
           
        </div>
        </>
       
    );
};

export default ItemBoxTime;
