import React, { Fragment, FC, useState, useRef, useEffect } from 'react';
import Swiper from 'react-id-swiper';
import { Modal, Spin } from 'antd';
import classNames from 'classnames';
import iconLeft from 'images/fillWhite/arrowLeft.svg';
import iconRight from 'images/fillWhite/arrowRight.svg';
import iconCheck from 'images/icons/iconTickBlue.svg';
import CommonImage from 'components/common/Image';
import editBg from 'images/icons/bg-edit-image.svg';
import editBgSelected from 'images/icons/bg-edit-image-selected.svg';
import { useMediaQuery } from 'react-responsive';
import { IStylist } from '../../../types/Stylist';

const ListStylistV2: FC<{
    listStylist: IStylist[];
    id: number;
    onClickItem: Function;
    isLoading: boolean;
    allowBookStylist: boolean;
    isStylistBusy: number;
    salonId: number;
    isShowStylist: boolean;
    stylitIdFromHistory: number;
}> = ({
    listStylist = [],
    id = 0,
    onClickItem,
    isLoading,
    allowBookStylist,
    isStylistBusy,
    isShowStylist,
    stylitIdFromHistory,
}) => {
    const swiperRef = useRef<any>(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [index, setIndex] = useState<number>(-1);
    const [isVisible, setIsVisible] = useState(false);

    const isMobile = useMediaQuery({
        query: '(max-width: 768px)',
    });

    const currentIndex = useRef<number>(-1);

    function onChangeItem(item: IStylist, key: number) {
        // clickDefault
        if (item.staffId === 0) {
            onClickItem({ staffId: 0, staffName: '' });
        } else {
            onClickItem(item);
        }
        currentIndex.current = key;
    }

    const paramsSwiper = {
        initialSlide: 1,
        spaceBetween: 10,
        cssMode: true,
        breakpoints: {
            768: {
                slidesPerView: 3.8,
            },
            0: {
                slidesPerView: 3.3,
            },
        },
        freeMode: true,
    };

    const [isFirstSlide, setIsFirstSlide] = useState(true);
    const [isLastSlide, setIsLastSlide] = useState(false);

    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
            setIsLastSlide(swiperRef.current.swiper.isEnd);
        }
    };

    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
            setIsLastSlide(swiperRef.current.swiper.isEnd);
        }
    };

    function handleSlideInit() {
        if (swiperRef.current && swiperRef.current.swiper) {
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
            setIsLastSlide(swiperRef.current.swiper.isEnd);
        }
    }

    const handleSlideChange = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
        }
    };

    const handleTouchChange = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
            setIsLastSlide(swiperRef.current.swiper.isEnd);
        }
    };

    useEffect(() => {
        if (isShowStylist) {
            if (swiperRef.current && swiperRef.current.swiper) {
                swiperRef.current.swiper.update();    
                setIsFirstSlide(swiperRef.current.swiper.isBeginning);
                setIsLastSlide(swiperRef.current.swiper.isEnd);
            }
        }
    }, [isShowStylist]);

    useEffect(() => {
        if (isStylistBusy > 0) {
            setIndex(currentIndex.current);
        } else {
            setIndex(-1);
        }
    }, [isStylistBusy]);

    useEffect(() => {
        currentIndex.current = -1;
    }, [listStylist]);

    useEffect(() => {
        if (!isLoading && id > 0) {
            setIndex(-1);
            const indexStylistSelect = listStylist.findIndex(
                // eslint-disable-next-line eqeqeq
                (stylist) => stylist.staffId == id,
            );
            if (swiperRef.current && indexStylistSelect > -1) {
                const indexToScroll =
                    indexStylistSelect - 1 >= 0
                        ? indexStylistSelect - 1
                        : indexStylistSelect;
                swiperRef.current.swiper.slideTo(indexToScroll, 200);
                swiperRef.current.swiper.update();
            }
        }
    }, [isLoading, isShowStylist]);

    const classButtonNext = isLastSlide
        ? 'btn-action btn-right btn_hide'
        : 'btn-action btn-right';
    const classButtonPrev = isFirstSlide
        ? 'btn-action btn-left btn_hide'
        : 'btn-action btn-left';

    useEffect(() => {
        if (stylitIdFromHistory && listStylist.length > 0) {
            const dataIndex = listStylist.findIndex(
                (item) => item.staffId === stylitIdFromHistory,
            );
            if (dataIndex > -1) {
                onChangeItem(listStylist[dataIndex], dataIndex);
            } else {
                setIsVisible(true);
            }
        }
    }, [stylitIdFromHistory, listStylist]);

    const onCancel = () => {
        setIsVisible(false);
    };

    const classContent = classNames('selectStylist', {
        'loading-stylist': isLoading,
        'fade-in': isShowStylist,
        'hide-stylist': !isShowStylist,
    });

    return (
        <div className={classContent}>
            <div className="relative w-full overflow-y-hidden">
                {!isMobile && (
                    <>
                        <div
                            className={classButtonPrev}
                            onClick={goPrev}
                            role="presentation"
                        >
                            <img src={iconLeft} alt="" className="icon-btn" />
                        </div>
                        <div
                            className={classButtonNext}
                            onClick={goNext}
                            role="presentation"
                        >
                            <img src={iconRight} alt="" className="icon-btn" />
                        </div>
                    </>
                )}

                {listStylist.length ? (
                    <Swiper
                        ref={swiperRef}
                        on={{
                            touchMove: handleTouchChange,
                            slideChange: handleSlideChange,
                            init: handleSlideInit,
                        }}
                        key={listStylist.length}
                        {...paramsSwiper}
                    >
                        {[
                            {
                                staffId: 0,
                                staffName: 'Moyo Chọn Hộ Bạn',
                                special: false,
                                avatar: '/moyo-chon-ho-ban.svg',
                            },
                            ...listStylist,
                        ].map((item: IStylist, key) => {
                            const name = item?.staffName?.trim().split(' ');
                            return (
                                <div>
                                    <div
                                        style={{
                                            display: item.avatar ? '' : '',
                                        }}
                                        key={`${key + 1}_${item.staffId}`}
                                        onClick={() => onChangeItem(item, key)}
                                        role="presentation"
                                    >
                                        <div
                                            className={`relative rounded-md mt-[10px] border-2 w-full aspect-[9/16] ${item.staffId === id
                                                    ? 'border-[#1877F2]'
                                                    : 'border-transparent'
                                                }`}
                                        >
                                            <CommonImage
                                                src={item.avatar ?? ''}
                                                className={`h-full overflow-hidden object-cover min-w-[83px] ${item.staffId === id ||
                                                    'rounded-md'
                                                    }`}
                                            />
                                            {item.skillLevel &&
                                                item.skillLevel > 1 ? (
                                                <div className="absolute top-[-3px] left-0">
                                                    <span>⭐</span>
                                                </div>
                                            ) : null}
                                            {item.staffId === id && (
                                                <img
                                                    className="absolute top-[-10px] right-[-10px]"
                                                    src={iconCheck}
                                                    alt=""
                                                />
                                            )}
                                        </div>
                                        <div className="flex flex-col justify-center h-8 text-[11px] text-center">
                                        <div className="capitalize">
                                                    {key === 0 ? (
                                                        <span>
                                                            {item.staffName}
                                                        </span>
                                                    ) : (
                                                        <span>
                                                                    {`${name && name[
                                                                        name.length -
                                                                        1
                                                                        ] || ''
                                                                } ${name && name[0] || ''
                                                                }`.toLocaleLowerCase()}
                                                        </span>
                                                    )}
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Swiper>
                ) : null}
            </div>
                <Modal
                    title="THÔNG BÁO"
                    className="modal-stylist"
                    visible={isVisible}
                    footer={null}
                    onCancel={onCancel}
                >
                    <div className="text-center">
                        <div>
                            Stylist hôm nay bận,
                            <br />
                            Mời bạn chọn stylist khác!
                        </div>
                        <div
                            className="btn h3 bg-30s mg-top-20 padding-5"
                            onClick={() => onCancel()}
                        >
                            ĐỒNG Ý
                        </div>
                    </div>
                </Modal>
                {isLoading && (
                    <div className="absolute background-spin">
                        <div className="center-middle">
                            <Spin size="large" />
                        </div>
                    </div>
                )}
            </div>
        );
    };

export default ListStylistV2;
