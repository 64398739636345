import React, {
    useState,
    useEffect,
    Fragment,
    useRef,
    // useCallback,
} from 'react';
import { useCookies } from 'react-cookie';
import { Input, Button, notification } from 'antd';
import API from 'repository';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import iconBack from 'images/icons/chevronLeft.svg';
import { formatPhone } from 'utils/NumberUtils';
import { fetchUser } from 'actions/user';
import OtpInput from 'components/OtpInput/index.js';
import { setCookieFunc } from 'components/func/Function';
import trackingConst from 'config/TrackingConst';

import './index.scss';
import './indexV2.scss';
import { setIsHandleMenuBooked } from 'actions/common';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Step2Captcha } from './Step2Captcha';

const ER = 'Hệ thống bị gián đoạn xin vui lòng thử lại sau!';

const FormLogin = ({
    onChangeText,
    onCancel,
    modalLoginTitle,
    fetchUser,
    phoneBooking,
    trackingData,
    userId,
    actions,
    setIsHandleMenuBooked,
}) => {
    const innitState = {
        phone: '',
        password: '',
        step: 1,
        loading: false,
        name: '',
        otp: '',
        isPhoneRegister: false,
        error: '',
        captcha: false,
    };
    const [otpCount, setOtpCount] = useState(0);
    const [countReSendOtp, setCountReSendOtp] = useState(0);
    const [verificationMethod, setVerificationMethod] = useState('');
    const [state, setState] = useState(innitState);
    const inputPhone = useRef(null);
    const KEY_ENTER = 13;
    const [cookies, setCookie] = useCookies(['_cus_accessToken2']);
    const [countErrorLogin, setCountErrorLogin] = useState(0);

    const showMessage = (message, type) => {
        notification.close('my-notifice');
        const config = {
            message: 'Thông Báo',
            description: message,
            duration: 5,
            className: type,
            key: 'my-notifice',
        };
        notification.success(config);
    };

    const onKeyDown = (e, param) => {
        if (e.keyCode === KEY_ENTER) {
            switch (param) {
                case 'otp':
                    actionValidateOtp();
                    break;
                case 'create-password':
                    actionChangePassword();
                    break;
                default:
                    break;
            }
        }
    };

    const commonDataTracking = {
        pageId: trackingConst.Pages.COMMON_PAGE,
        screenId: trackingConst.Screens.LOGIN_SCREEN,
        eventId: '',
        tokenKey: trackingData.token,
        source: trackingData.source,
        utmSource: trackingData.utmSource,
        data: {
            customerId: userId || 0,
            phone: state.phone.replace(/\./g, '') || '',
        },
    };

    async function postTracking(params) {
        try {
            await API.postTrackingDynamic(params);
        } catch (e) {
            console.log(e.message);
        }
    }

    const onChangePhone = (e) => {
        const { value } = e.target;
        if (value[0] === '0') {
            setState({ ...state, phone: formatPhone(value), error: '' });
        } else {
            setState({
                ...state,
                phone: '',
                error: 'Số điện thoại sai định dạng.',
            });
        }
    };

    const getPhone = () => {
        const myPhone = state.phone.replace(/\./g, '');
        if (myPhone.length === 10 && myPhone[0] === '0') {
            let _phone = myPhone;
            if (_phone[0] === '0') {
                _phone = myPhone.slice(1);
            }
            const phone = `+84${_phone}`;
            return phone;
        }
        setState({ ...state, error: 'Số điện thoại sai định dạng.' });
        return false;
    };

    const actionChangePassword = async () => {
        const { password } = state;
        const phone = getPhone();
        if (password.length !== 6) {
            setState({
                ...state,
                error: 'Mật khẩu sai định dạng.',
            });
            return;
        }
        try {
            setState({
                ...state,
                loading: true,
            });
            const data = {
                phone,
                newPassword: password,
                accessToken: localStorage.getItem('accessToken'),
            };
            const res = await API.changePassword(data);
            if (res.statusCode === 200) {
                localStorage.setItem('accessToken', res.data.accessToken);
                localStorage.setItem('customerPhone', phone);
                setCookieFunc(
                    '_cus_accessToken2',
                    res.data.accessToken,
                    cookies,
                    setCookie,
                );

                await actions.fetchUser();
                showMessage('Đặt mật khẩu thành công!', 'success');
                setState({
                    ...state,
                    loading: false,
                    error: '',
                });
                onCancel();
            } else {
                setState({
                    ...state,
                    loading: false,
                    error: ER,
                });
            }
        } catch (er) {
            setState({
                ...state,
                loading: false,
            });
        }

        // tracking
        const params = {
            ...commonDataTracking,
            eventId: trackingConst.Events.CLICK_SAVING_PASSWORD,
        };
        postTracking(params);
    };

    const actionValidateOtp = async () => {
        const _phone = `0${getPhone().slice(3)}`;
        const { otp } = state;
        setVerificationMethod('');
        if (otp.length !== 6) {
            setState({ ...state, error: 'Mã xác nhận sai định dạng.' });
            return;
        }

        if (_phone) {
            try {
                setState({ ...state, loading: true });
                const { statusCode, data, message } = await API.validateOpt({
                    phone: _phone,
                    otp,
                });
                if (statusCode === 200) {
                    localStorage.setItem('customerPhone', _phone);
                    localStorage.setItem('accessToken', data.accessToken);
                    setCookieFunc(
                        '_cus_accessToken2',
                        data.accessToken,
                        cookies,
                        setCookie,
                    );
                    const user = await actions.fetchUser();
                    if (user.data.userInfo.userIdSql) {
                        const params = {
                            ...commonDataTracking,
                            eventId:
                                trackingConst.Events.GO_TO_STEP_CREATE_PASSWORD,
                            data: {
                                customerId: user.data.userInfo.userIdSql,
                                phone: state.phone.replace(/\./g, '') || '',
                            },
                        };
                        postTracking(params);
                        setState({
                            ...state,
                            loading: false,
                            otp: '',
                            error: '',
                            password: '',
                            step: 4,
                        });
                    }
                } else {
                    setState({
                        ...state,
                        loading: false,
                        error: message,
                        password: '',
                    });
                }
            } catch (e) {
                setState({
                    ...state,
                    loading: false,
                    error: 'Mã xác nhận không chính xác.',
                });
            }
        }
    };

    const onChangeOtp = (value) => {
        if (
            (value.charCodeAt(value.length - 1) > 47 &&
                value.charCodeAt(value.length - 1) < 58) ||
            !value
        ) {
            setState({ ...state, otp: value, error: '' });
        } else {
            setVerificationMethod('');
            setState({
                ...state,
                error: 'Mã xác minh là số và 6 kí tự.',
            });
        }
    };

    const otpMethod = [
        'zalo',
        'sms', // 'voice'
    ];

    const { executeRecaptcha } = useGoogleReCaptcha();
    const handleReCaptchaVerify = async () => {
        console.log(
            `FormLoginV2: handleReCaptchaVerify -> executeRecaptcha`,
            executeRecaptcha,
        );
        if (executeRecaptcha) {
            const token = await executeRecaptcha('');
            if (token) return token;
        }
        return '';
    };

    const sentOtp = async () => {
        // Reset verification method
        setVerificationMethod('');
        setState({ ...state, otp: '', error: '' });
        const _phone = `0${getPhone().slice(3)}`;
        if (_phone && !state.loading) {
            try {
                setState({
                    ...state,
                    loading: true,
                    error: '',
                });
                setCountReSendOtp(0);

                // const data = await API.getOpt({ phone: _phone, method: isSmsMethod ? 'sms' : 'voice' });
                const token = await handleReCaptchaVerify();
                const data = await API.getOpt({
                    phone: _phone,
                    method:
                        otpCount > otpMethod.length
                            ? otpMethod[otpCount % otpMethod.length]
                            : otpMethod[otpCount], // isSmsMethod ? 'sms' : 'voice',
                    recaptcha: token,
                });

                if (data) {
                    setCountReSendOtp(data.allowResendAfterSeconds);
                    setVerificationMethod(data.verificationMethod);
                    console.log(
                        `setVerificationMethod: ${data.verificationMethod}`,
                    );
                    if (data.success) {
                        setState({
                            ...state,
                            error: '',
                            otp: '',
                            loading: false,
                        });
                    } else {
                        console.log('sendOtp fail', data.success, data.message);
                        setState({
                            ...state,
                            error: data.message,
                            otp: '',
                            loading: false,
                        });
                    }
                }
            } catch (er) {
                console.log('Error sendOtp', er);
                setState({
                    ...state,
                    loading: false,
                    error: ER,
                    otp: '',
                });
            } finally {
                setOtpCount(otpCount + 1);
            }
        }
    };

    const reSendOtp = () => {
        setState({ ...state, otp: '', error: '' });
        sentOtp();
    };

    const handleIsCheckNew = async () => {
        const phone = getPhone();
        if (phone) {
            try {
                setState({ ...state, loading: true });
                const value = { phone };
                const {
                    data: { isNewUser },
                    statusCode,
                } = await API.checkIsNewLogin(value);
                if (statusCode === 200 && isNewUser) {
                    await sentOtp();
                    setState({
                        ...state,
                        isPhoneRegister: true,
                        loading: false,
                        error: '',
                        step: 3,
                    });
                } else {
                    setState({
                        ...state,
                        loading: false,
                        error: '',
                        step: 2,
                    });
                }
            } catch (er) {
                setState({ ...state, loading: false, error: ER });
                console.log(er);
            }
        } else {
            setState({ ...state, error: 'Số điện thoại sai định dạng.' });
        }
    };

    const prevStep = () => {
        setState({ ...state, step: state.step - 1 });
    };

    const onChangeClick = () => {
        setState({ ...state, step: 1 });
    };

    const actionSendOtp = async () => {
        const phone = getPhone();
        if (phone) {
            try {
                await sentOtp();
                setState({
                    ...state,
                    step: 3,
                    error: '',
                });
            } catch (error) {
                setState({
                    ...state,
                    error: ER,
                });
            }
        }

        // tracking
        const params = {
            ...commonDataTracking,
            eventId: trackingConst.Events.CLICK_FORGOT_PASSWORD,
        };
        postTracking(params);
    };

    const onChangePassword = (key, value) => {
        if (
            (value.charCodeAt(value.length - 1) > 47 &&
                value.charCodeAt(value.length - 1) < 58) ||
            !value
        ) {
            setState({ ...state, error: '', [key]: value });
        } else {
            setState({
                ...state,
                error: 'Mật khẩu phải là số và 6 kí tự.',
                [key]: '',
            });
        }
    };

    const handleSkipSavingPassword = () => {
        onCancel();

        // tracking
        const params = {
            ...commonDataTracking,
            eventId: trackingConst.Events.CLICK_SKIP_SAVING_PASSWORD,
        };
        postTracking(params);
    };

    useEffect(() => {
        const { step } = state;

        // TRACKING
        const params = {
            ...commonDataTracking,
        };

        switch (step) {
            case 1:
                setState(innitState);
                if (modalLoginTitle === '') {
                    onChangeText('ĐĂNG NHẬP');
                    setIsHandleMenuBooked(false);
                } else {
                    onChangeText(modalLoginTitle);
                }
                inputPhone.current.focus();
                params.eventId = trackingConst.Events.GO_TO_STEP_INPUT_PHONE;
                break;
            case 2:
                if (modalLoginTitle === '') onChangeText('NHẬP MẬT KHẨU');
                else onChangeText(modalLoginTitle);
                params.eventId = trackingConst.Events.GO_TO_STEP_INPUT_PASSWORD;
                setCountErrorLogin(0);
                break;
            case 3:
                onChangeText('NHẬP MÃ XÁC NHẬN');
                params.eventId = trackingConst.Events.GO_TO_STEP_CONFIRM_CODE;
                break;
            case 4:
                if (state.isPhoneRegister) {
                    onChangeText('TẠO TÀI KHOẢN THÀNH CÔNG');
                } else {
                    onChangeText('ĐẶT MẬT KHẨU');
                }
                break;
            case 5:
                params.eventId = trackingConst.Events.GO_TO_STEP_END_LOGIN;
                break;
            default:
                break;
        }

        // TRACKING
        if (params.eventId) {
            postTracking(params);
        }
    }, [state.step]);

    useEffect(() => {
        if (phoneBooking) {
            setState({ ...state, phone: formatPhone(phoneBooking) });
        }
    }, [phoneBooking]);

    // useEffect(() => {
    //     if (state.step === 2) setCountErrorLogin(0);
    // }, [state.step]);

    useEffect(() => {
        if (phoneBooking && state.phone) {
            handleIsCheckNew();
        }
    }, [phoneBooking, state.phone]);

    const {
        phone,
        step,
        loading,
        password,
        otp,
        error,
        isPhoneRegister,
    } = state;
    const commonProps = {
        countErrorLogin,
        setCountErrorLogin,
        prevStep,
        onChangePassword,
        onKeyDown,
        actionSendOtp,
        state,
        setState,
        getPhone,
        actions,
        commonDataTracking,
        postTracking,
        showMessage,
        onCancel,
        phoneBooking,
    };

    const captchaProps = {
        ...commonProps,
        isCaptcha: countErrorLogin > 2,
    };
    return (
        <div className="form-login-V2">
            {step === 1 && (
                <Fragment>
                    <div className="form__text">
                        {!phoneBooking
                            ? 'Số điện thoại của bạn là gì ạ?'
                            : 'Bạn vui lòng xác nhận số điện thoại'}
                    </div>
                    <div className="form__input">
                        <Input
                            onChange={onChangePhone}
                            value={phone}
                            disabled={step === 2}
                            type="tel"
                            placeholder="Ví dụ: 0912.xxx.xxx"
                            ref={inputPhone}
                            onPressEnter={handleIsCheckNew}
                        />
                    </div>
                    {error && <div className="form__message">{error}</div>}
                    <Button
                        onClick={handleIsCheckNew}
                        className="form__button btn-color-1"
                        loading={loading}
                    >
                        TIẾP TỤC
                    </Button>
                </Fragment>
            )}
            {step === 2 && (
                <>
                    {countErrorLogin > 2 ? (
                        <Step2Captcha {...captchaProps} />
                    ) : (
                        <Step2Captcha {...commonProps} isCaptcha={false} />
                    )}
                </>
            )}

            {step === 3 && (
                <Fragment>
                    {!phoneBooking ? (
                        <div
                            className="from__btn btn-back"
                            onClick={onChangeClick}
                            role="presentation"
                        >
                            <img src={iconBack} alt="back-icon" />
                        </div>
                    ) : null}
                    <div className="form__text">
                        Bạn nhập mã xác nhận (6 chữ số) được gửi đến SĐT{' '}
                        <b>{phone}</b> {isPhoneRegister && 'để hoàn tất'}
                    </div>
                    <div className="form__input form__input-pin">
                        <OtpInput
                            value={otp}
                            onChange={onChangeOtp}
                            numInputs={6}
                            isInputNum
                            focusStyle={{ borderColor: '#222222' }}
                            shouldAutoFocus
                            onKeyDown={(e) => onKeyDown(e, 'otp')}
                        />
                    </div>
                    {/* {error && <div className="form__message">{error}</div>} */}
                    <SentOtp
                        getPhone={getPhone}
                        reSendOtp={reSendOtp}
                        step={step}
                        countReSendOtp={countReSendOtp}
                        verificationMethod={verificationMethod}
                        error={error}
                        otpMethod={otpMethod}
                    />
                    <Button
                        type="primary"
                        onClick={actionValidateOtp}
                        className="form__button btn-color-1"
                        loading={loading}
                    >
                        HOÀN TẤT
                    </Button>
                </Fragment>
            )}

            {step === 4 && (
                <div>
                    <Fragment>
                        <div className="form__text">
                            {isPhoneRegister
                                ? 'Bạn có thể tạo mật khẩu (gồm 6 số) để đăng nhập nếu muốn.'
                                : 'Bạn có thể đặt mật khẩu (gồm 6 số) để đăng nhập nếu muốn.'}
                        </div>
                        <div className="form__input form__input-pin">
                            <OtpInput
                                value={password}
                                onChange={(value) =>
                                    onChangePassword('password', value)
                                }
                                numInputs={6}
                                isInputNum
                                focusStyle={{ borderColor: '#222222' }}
                                shouldAutoFocus
                                onKeyDown={(e) =>
                                    onKeyDown(e, 'create-password')
                                }
                            />
                        </div>
                        {error && <div className="form__message">{error}</div>}
                        <div className="flex">
                            <Button
                                type="primary"
                                onClick={handleSkipSavingPassword}
                                style={{ marginRight: 10 }}
                                className="form__button btn-color-1"
                            >
                                BỎ QUA
                            </Button>
                            <Button
                                type="primary"
                                onClick={actionChangePassword}
                                className="form__button btn-color-1"
                                loading={loading}
                            >
                                LƯU MẬT KHẨU
                            </Button>
                        </div>
                    </Fragment>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    const { booking, trackingData, user, common } = state;
    return {
        phoneBooking: booking.phoneBooking,
        trackingData,
        userId: user.userId,
        modalLoginTitle: common.modalLoginTitle,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            ...bindActionCreators(
                {
                    fetchUser,
                },
                dispatch,
            ),
        },
        setIsHandleMenuBooked: (value) =>
            dispatch(setIsHandleMenuBooked(value)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormLogin);

const SentOtp = (props) => {
    const {
        countReSendOtp,
        step,
        getPhone,
        reSendOtp,
        verificationMethod,
        error,
        otpMethod,
    } = props;
    const [count, setCount] = useState(countReSendOtp);

    const sendOtp = () => {
        if (getPhone() && count <= 0) {
            reSendOtp();
        }
    };

    useEffect(() => {
        setCount(countReSendOtp);
    }, [countReSendOtp]);

    useEffect(() => {
        if (count > 0) {
            setTimeout(() => {
                setCount((c) => c - 1);
            }, 1000);
        }
    }, [count]);

    useEffect(() => {
        if (step !== 3) setCount(0);
    }, [step]);

    const show = count > 0 ? 'hide' : '';

    const getMessage = (() => {
        if (error) return error;

        switch (verificationMethod) {
            case 'sms':
                return 'Kiểm tra SMS để lấy OTP đăng nhập.';
            case 'voice':
                return 'Đợi cuộc gọi tự động để lấy OTP đăng nhập.';
            case 'zalo':
                return 'Kiểm tra Zalo để lấy OTP đăng nhập.';
            default:
                return 'Hệ thống gián đoạn, vui lòng thực hiện lại';
        }
    })();

    const getMethodName = (method) => {
        switch (method) {
            case 'sms':
                return 'sms';
            case 'voice':
                return 'cuộc gọi';
            case 'zalo':
                return 'zalo';
            default:
                return '';
        }
    };

    const getResendMessage = (() => {
        const currentMethodIndex = otpMethod.indexOf(verificationMethod);
        const methodNext =
            currentMethodIndex == otpMethod.length - 1
                ? otpMethod[0]
                : otpMethod[currentMethodIndex + 1];

        const methodNextName = getMethodName(methodNext);
        switch (verificationMethod) {
            case 'sms':
                return `Không nhận được sms, gửi lại otp qua ${methodNextName}`;
            case 'voice':
                return `Không nhận được cuộc gọi, gửi lại otp qua ${methodNextName}`;
            case 'zalo':
                return `Không nhận được zalo, gửi lại otp qua ${methodNextName}`;
            default:
                return '';
        }
    })();

    return (
        <div className="sendOTP">
            {/* {verificationMethod && (
                <div className="form__message">
                    {verificationMethod === 'sms'
                        ? 'Kiểm tra SMS '
                        : 'Đợi cuộc gọi tự động '}
                    để lấy OTP đăng nhập.
                </div>
            )} */}
            <div className="form__message">{getMessage}</div>

            <div>
                <span
                    className={`sendOTP__resend ${show}`}
                    // onClick={() => sendOtp(verificationMethod == 'sms' ? true : false)}
                    onClick={() => sendOtp()}
                    role="presentation"
                >
                    Gửi lại OTP
                </span>
                {count > 0 && (
                    <span className="sendOTP__count">sau {count} giây</span>
                )}
            </div>
            {count == 0 && (
                <div>
                    <span
                        className={`sendOTP__resend ${show}`}
                        onClick={() => {
                            sendOtp(verificationMethod != 'sms');
                        }}
                        role="presentation"
                    >
                        {/* Không nhận được {verificationMethod == 'sms' ? 'tin nhắn' : 'cuộc gọi'}, gửi otp qua {verificationMethod == 'sms' ? 'cuộc gọi' : 'SMS'} */}
                        {getResendMessage}
                    </span>
                </div>
            )}
        </div>
    );
};
