import React, { FC } from 'react';
import { Modal } from 'antd';
import { IStylist } from '../../../types/Stylist';

const ModalStylistMoveSalon: FC<{
    onCancel: Function;
    visible: boolean;
    onHandleMoveSalonStylist: Function;
    salonMove: any;
    stylist: IStylist;
}> = ({ onCancel, visible, onHandleMoveSalonStylist, stylist, salonMove }) => {
    return (
        <Modal
            className="modal-stylist"
            title="THÔNG BÁO"
            visible={visible}
            footer={null}
            onCancel={() => onCancel()}
        >
            <div style={{ marginBottom: 15 }}>
                <span>
                    {' '}
                    Stylist{' '}
                    <span className="name-stylist">
                        {' '}
                        {stylist.staffName}{' '}
                    </span>{' '}
                    đã chuyển đến salon{' '}
                    <span className="name-stylist"> {salonMove?.name} </span>
                </span>
                <div>Chúng mình mời Bạn</div>
            </div>
            <div
                className="btn-action h2 br-4 padding-20 pointer"
                onClick={() => onCancel()}
                role="presentation"
            >
                Hệ thống hãy chọn cho tôi stylist tốt nhất
            </div>
            <div
                className="h3 br-4 padding-5 pointer btn-change-salon"
                onClick={() => onHandleMoveSalonStylist()}
                role="presentation"
            >
                Đặt lịch stylist này tại <br /> salon {salonMove?.name}
            </div>
        </Modal>
    );
};

export default ModalStylistMoveSalon;
